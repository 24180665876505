import 'doctor/styles/wallet.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import {
  fetchIncomeTransactionsRequest,
  fetchPayoutTransactionsRequest,
} from 'shared/redux/actions/TransactionActions';
import {
  getIncomeTransactionsState,
  getMlmBonusesState,
  getPayoutTransactionsState,
  getUserProfileState,
  referralState,
} from 'shared/redux/src/StatesGetter';
import { getPrivateMediaFileRequest } from 'shared/redux/actions/UngroupedActions';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';
import { useLocation } from 'react-router';
import { translatedFullDate } from 'shared/modules/DateTimeUtils';
import { getAppointmentDetailsRequest } from 'shared/redux/actions/DoctorAppointmentsActions';
import Alerts from 'shared/components/Alerts';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { getEnv } from '../../../shared/services/EnvService';
import { fetchReferralBonusRequest } from '../../../shared/redux/actions/ReferralsActions';

type LocationState = {
  data: Record<string, never>;
  type: string;
};

const DoctorWalletUnfolded = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { data = {}, type = '' } = location?.state as LocationState;
  const dateString = data?.dateString ?? '';
  const dateStart = data?.dateStart ?? dayjs().format('YYYY-MM-DD HH:mm:ss');
  const dateEnd = data?.dateEnd ?? dayjs().format('YYYY-MM-DD HH:mm:ss');
  const amount = data?.amount;
  const payoutId = data?.payoutId ?? 0;
  const { isOrganizationOwner } = useSelector(getUserProfileState, shallowEqual);
  const transactionsState = useSelector(
    type === 'income' ? getIncomeTransactionsState : getPayoutTransactionsState,
    shallowEqual,
  );
  const { data: transactions, currentPage, noOfPages } = transactionsState;
  const [listItems, setListItems] = useState([]);
  const notInitialRender = useRef(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const fetchResults = () => {
    if (type === 'income') {
      dispatch(fetchIncomeTransactionsRequest({ dateStart, dateEnd, limit, page }));
      return;
    }
    dispatch(fetchPayoutTransactionsRequest({ payoutId, limit, page }));
  };
  const bonusState = useSelector(getMlmBonusesState, shallowEqual);
  // @ts-ignore
  const referralKey = dateStart.substring(0, 7);
  const [totalReferralBonus, setTotalReferralBonus] = useState('0 Ron');
  useEffect(() => {
    dispatch(fetchReferralBonusRequest({}));
  }, []);

  useEffect(() => {
    if (bonusState !== undefined) {
      setTotalReferralBonus(bonusState[referralKey]?.totalAsString);
    }
  }, [bonusState]);

  useEffect(() => {
    if (type === 'income') {
      dispatch(fetchIncomeTransactionsRequest({ dateStart, dateEnd }));
    } else {
      dispatch(fetchPayoutTransactionsRequest({ payoutId }));
    }
    const newArray = _.unionBy(listItems, transactions, 'id');
    setListItems(newArray);
  }, []);
  useEffect(() => {
    if (notInitialRender.current) {
      setListItems([]);
      if (page === 1) {
        fetchResults();
      } else {
        setPage(1);
      }
    }
    notInitialRender.current = true;
  }, []);
  useEffect(() => {
    const newArray = _.unionBy(listItems, transactions, 'id');
    setListItems(newArray);
  }, [transactionsState]);
  useEffect(() => {
    fetchResults();
  }, [page]);
  const loadMore = () => {
    if (currentPage === noOfPages) return;
    const newPage = page + 1;
    setPage(newPage);
  };
  useEffect(() => {
    if (type === 'income') {
      dispatch(fetchIncomeTransactionsRequest({ dateStart, dateEnd }));
      return;
    }
    dispatch(fetchPayoutTransactionsRequest({ payoutId }));
  }, [type, type === 'income' ? dateStart : payoutId]);
  const viewFile = (mediaId, status) => {
    if (status !== 'available') {
      Alerts.simpleAlert(t('error'), t('alerts.fileNotReady'));
      return;
    }
    dispatch(getPrivateMediaFileRequest(mediaId));
  };
  const renderItem = (item) => {
    const {
      createdAt = dayjs().format('YYYY-MM-DD HH:mm:ss'),
      amountAsString = '',
      amountRefundedAsString = '',
      amountRefunded = 0,
      patientName = '',
      appointmentId = 0,
      consultationInvoiceId,
      platformFeeInvoiceId,
      consultationInvoiceStatus = '',
      platformFeeInvoiceStatus = '',
      platformFee = 0,
      platformFeeAsString = '',
    } = item;
    const goToDetails = (e) => {
      e.preventDefault();
      dispatch(getAppointmentDetailsRequest({ id: appointmentId }));
      navigate(`/doctor/consultation-details/${appointmentId}`, {
        state: {
          templateType: 'past',
        },
      });
    };
    return (
      <div className="unfolded-box" key={appointmentId.toString()}>
        <Link to="/" className="header" onClick={goToDetails}>
          <CustomIcon className="custom-icon" color={Colors.darkGrey} size="24" icon="Card" />
          <div className="details">
            <div className="amount">{amountAsString}</div>
            <div className="data" style={{ fontWeight: 700, marginBottom: 10 }}>{`${t(
              'platformFee',
            )} ${platformFeeAsString}`}</div>
            {patientName.length > 1 && <div className="fullName">{patientName}</div>}
            <div className="data">{translatedFullDate(createdAt)}</div>
          </div>
          <CustomIcon
            className="custom-icon arrow"
            color={Colors.darkGrey}
            size="24"
            icon="Right_pointer"
          />
        </Link>
        {amountRefunded > 0 && (
          <div className="amount-refunded">
            <CustomIcon
              className="custom-icon"
              color={Colors.warningColor}
              size="24"
              icon="Warning_2"
            />
            <div className="text">{t('refundedAmount')}</div>
            <div className="amount">{amountRefundedAsString}</div>
          </div>
        )}
        {isOrganizationOwner && (
          <>
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                viewFile(consultationInvoiceId, consultationInvoiceStatus);
              }}
              className="white-button download-invoice-btn"
            >
              <CustomIcon
                className="custom-icon"
                color={Colors.medicColor}
                size="24"
                icon="Download"
              />
              {t('downloadInvoiceService')}
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                viewFile(platformFeeInvoiceId, platformFeeInvoiceStatus);
              }}
              type="button"
              className="white-button download-invoice-btn"
            >
              <CustomIcon
                className="custom-icon"
                color={Colors.medicColor}
                size="24"
                icon="Download"
              />
              {t('downloadInvoiceCommission')}
            </button>
          </>
        )}
      </div>
    );
  };

  return (
    <section className="wallet">
      <div className="title-section transactions-data-wrapper">
        <h1>{t('mainScreenRoutes.details')}</h1>
        <div className="small-title-text">{dateString}</div>
      </div>
      <div className="total-box">
        <ListGroup>
          <ListGroup.Item>
            <div className="title">{t('wallet.earningsEstimate')}</div>
            <div className="details">{`${amount} ${getEnv('CURRENCY')}`}</div>
          </ListGroup.Item>
          {totalReferralBonus && (
            <ListGroup.Item>
              <div className="title">{t('wallet.bonus')}</div>
              <div className="details">{totalReferralBonus}</div>
            </ListGroup.Item>
          )}
        </ListGroup>
      </div>
      {/* {transactions.length > 0 && (
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
          }}
          className="white-button download-all-invoices-btn"
        >
          <CustomIcon className="custom-icon" color={Colors.darkGrey} size="24" icon="Download" />
          {t('downloadInvoices')}
        </button>
      )} */}
      {transactions.length > 0 ? (
        <InfiniteScroll
          dataLength={listItems.length}
          next={loadMore}
          hasMore={currentPage !== noOfPages}
          loader={<h4 style={{ textAlign: 'center' }}>Loading...</h4>}
          className="transactions-data-wrapper"
        >
          {listItems.map((transaction) => renderItem(transaction))}
        </InfiniteScroll>
      ) : (
        <div className="empty-container">{t('wallet.noTransactions')}</div>
      )}
    </section>
  );
};

DoctorWalletUnfolded.defaultProps = {
  match: {},
  location: {},
};
DoctorWalletUnfolded.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      data: PropTypes.shape({}),
      type: PropTypes.string,
    }),
  }),
};
export default DoctorWalletUnfolded;
