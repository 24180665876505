import { Link } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import infoCircleIcon from 'shared/assets/icons/info-circle-grey-icon.svg';
import { getAppointmentDetailsRequest } from 'shared/redux/actions/DoctorAppointmentsActions';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import Utils from '../../modules/Utils';

const PastConsultationListItem = ({ listItem, isDoctor, user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const goToDetails = (e, id) => {
    e.preventDefault();
    dispatch(getAppointmentDetailsRequest({ id, withNavigate: true, navigationTemplate: 'past' }));
  };

  const renderSubTitle = (appointment) => {
    if (appointment?.medicalRecords?.length) {
      return (
        <div className="year-files">
          {Utils.calculateAge(appointment?.patient?.birthDate) > 0 &&
            `${Utils.calculateAge(appointment?.patient?.birthDate)} ${t('years')} • `}
          <CustomIcon className="custom-icon" color={Colors.mediumGrey} size="15" icon="Document" />
          {t('attachedFiles')}
        </div>
      );
    }
    if (Utils.calculateAge(appointment?.patient?.birthDate) > 0) {
      return (
        <div className="year-files">{`${Utils.calculateAge(appointment?.patient?.birthDate)} ${t(
          'years',
        )}`}</div>
      );
    }
    return null;
  };

  const slug = listItem?.service?.slug ? listItem?.service?.slug : 'consultation';

  return (
    <div className="box past">
      <Link
        to={
          isDoctor
            ? `/doctor/consultation-details/${listItem.id}`
            : `/patient/consultations/consultation-details/${listItem.id}`
        }
        onClick={(e) => (isDoctor ? goToDetails(e, listItem.id) : {})}
        className="medic-info"
      >
        <div className="left-column">
          {!isDoctor && listItem?.type === 'callSpecialist' && !user?.id ? (
            <>
              <img
                src={infoCircleIcon}
                alt="doctor not found"
                style={{
                  width: '36px',
                  height: '36px',
                  marginRight: '20px',
                }}
              />
              <div className="info">
                <div className="fullName">{t('noSpecialistFound')}</div>
              </div>
            </>
          ) : (
            <>
              <div
                className="avatar"
                style={
                  user?.imgUrl
                    ? {
                        backgroundImage: `url(${user?.imgUrl})`,
                      }
                    : { backgroundColor: '#E5E7E8' }
                }
              >
                {!user?.imgUrl && (
                  <div className="image-placeholder">{`${user?.firstName?.charAt(
                    0,
                  )}${user?.lastName?.charAt(0)}`}</div>
                )}
              </div>
              <div className="info">
                <div className="fullName">{user?.fullNameWithTitle}</div>
                <div className="specialization">
                  {isDoctor ? renderSubTitle(listItem) : listItem.doctor?.specialization?.name}
                </div>
                <div className="type">
                  {listItem.doctor?.specialization?.name} • {Utils.capitalizeFirstLetter(t(slug))}
                </div>
              </div>
            </>
          )}

          <CustomIcon
            className="custom-icon arrow-right"
            color={Colors.darkGrey}
            size="24"
            icon="Right_pointer"
          />
        </div>
        <div className="right-column">
          <ListGroup>
            <ListGroup.Item>
              <div className="title">{t('dateAndTime')}</div>
              <div className="details">{dayjs(listItem.date).format('DD MMM YYYY • HH:mm')}</div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="title">{Utils.capitalizeFirstLetter(t(slug))}</div>
              <div className="details">{listItem.priceAsString}</div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="title">{t('state')}</div>
              <div className="details">{listItem.status}</div>
            </ListGroup.Item>
            {listItem.serverStatus.indexOf('cancelled') !== 0 && (
              <ListGroup.Item>
                <div className="title">{t('recommendation')}</div>
                <div className="details">
                  {listItem.conclusion && listItem.serverStatus === 'completed' ? (
                    <>
                      {isDoctor ? (
                        <span style={{ color: '#00CFB4' }}>{t('recommendationSent')}</span>
                      ) : (
                        <span style={{ color: '#00CFB4' }}>{t('recommendationReceived')}</span>
                      )}
                    </>
                  ) : (
                    <>
                      {isDoctor ? (
                        <span style={{ color: '#EB3300' }}>{t('recommendationNotSent')}</span>
                      ) : (
                        <span style={{ color: '#EB3300' }}>{t('recommendationNotReceived')}</span>
                      )}
                    </>
                  )}
                </div>
              </ListGroup.Item>
            )}
          </ListGroup>
        </div>
      </Link>
    </div>
  );
};

export default PastConsultationListItem;
