import 'doctor/styles/auth.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { requestLogout } from 'shared/redux/actions/AuthActions';
import { getAuthState } from 'shared/redux/src/StatesGetter';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fetchUserProfileRequest } from 'shared/redux/actions/UserProfileActions';
import { updateSharedStateAction } from 'shared/redux/actions/UngroupedActions';
import DoctorAccountProfileTabSpecialization from 'doctor/components/account/DoctorAccountProfileTabSpecialization';

const DoctorRegisterSpecialization = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isProfileCompleted, lastUpdate, isLoggedIn } = useSelector(getAuthState, shallowEqual);
  useEffect(() => {
    dispatch(fetchUserProfileRequest({}));
  }, []);
  useEffect(() => {
    if (isProfileCompleted === true && lastUpdate === 'justNow') {
      dispatch(updateSharedStateAction({ lastUpdate: 'longTimeAgo', isLoggedIn: true }));
      setTimeout(() => {
        navigate('/doctor/home');
      }, 200);
    } else if (!isLoggedIn && isProfileCompleted !== false) {
      navigate('/doctor/auth');
    }
  }, [isProfileCompleted, isLoggedIn]);
  return (
    <section className="register-steps">
      <div className="title-section">
        <div className="steps">{t('step')} 3/3</div>
        <h1>{t('medicalApp.specialization')}</h1>
      </div>
      <DoctorAccountProfileTabSpecialization type="unprofiled" />
    </section>
  );
};

export default DoctorRegisterSpecialization;
