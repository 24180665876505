import React from 'react';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { createLoadingSelector } from '../../../shared/redux/src/GenericSeletors';

const PatientMedicCardButton = (props) => {
  const { t } = useTranslation();
  const { status, onClickEvent, upcomingConsultation, listItemSchedule, centerTextButton } = props;
  const loadingSelector = createLoadingSelector();
  const isLoading = useSelector(loadingSelector);
  const availableTextTo = (item) => {
    if (item) {
      const day = dayjs(item).format('DD');
      if (day === dayjs().format('DD') || day === dayjs().add(1, 'day').format('DD')) {
        return t('medicalApp.availability');
      }
    }

    return t('medicalApp.availabilityFrom');
  };

  const btnSwitch = (param) => {
    switch (param) {
      case 'online':
        return (
          <button
            type="button"
            disabled={!!isLoading}
            onClick={onClickEvent}
            className="call-now-btn medic-action-btn cursor-pointer"
          >
            <CustomIcon className="custom-icon" color={Colors.white} size="24" icon="Video" />
            <div className="call-now-btn-text">{t('callNow')}</div>
          </button>
        );
      case 'busy':
        return <div className="busy-btn medic-action-btn bussy-button">{t('busy')}</div>;
      case 'offline':
        return <div />;
      // <div className="offline-btn medic-action-btn">
      //   {!upcomingConsultation ? (
      //     'Offline'
      //   ) : (
      //     <>
      //       <CustomIcon
      //         className="custom-icon"
      //         color={Colors.mediumGrey}
      //         size="24"
      //         icon="Video"
      //       />
      //       {t('callNow')}
      //     </>
      //   )}
      // </div>
      default:
        return (
          <div onClick={onClickEvent} className="appointment-btn medic-action-btn cursor-pointer">
            {centerTextButton ? (
              <div className="align-items-center">
                <div className="appointment-btn-icon">
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.darkGrey}
                    size="22"
                    icon="Add_appointment"
                  />
                </div>
                <div className="appointment-btn-texts">
                  <div className="appointment-btn-texts-schedule">{t('scheduleTitle')}</div>
                  {listItemSchedule?.firstAvailableTime && (
                    <div className="d-flex justify-content-between" onClick={onClickEvent}>
                      <div className="subtitle">
                        {listItemSchedule.rawFirstAvailableTime &&
                          availableTextTo(listItemSchedule.rawFirstAvailableTime)}
                        {` ${listItemSchedule.firstAvailableTime}`}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <>
                <div className="appointment-btn-icon">
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.darkGrey}
                    size="22"
                    icon="Add_appointment"
                  />
                </div>
                <div className="appointment-btn-texts">
                  <div className="appointment-btn-texts-schedule">{t('scheduleTitle')}</div>
                  {listItemSchedule?.firstAvailableTime && (
                    <div className="d-flex justify-content-between" onClick={onClickEvent}>
                      <div className="subtitle">
                        {listItemSchedule.rawFirstAvailableTime &&
                          availableTextTo(listItemSchedule.rawFirstAvailableTime)}
                        {` ${listItemSchedule.firstAvailableTime}`}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        );
    }
  };

  return btnSwitch(status);
};

export default PatientMedicCardButton;
