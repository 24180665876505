import React, { useEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import Alerts from 'shared/components/Alerts';
import {
  addAppointmentConclusionRequest,
  addAppointmentConclusionSuccess,
  addAppointmentPrescriptionMedicationRequest,
  addAppointmentPrescriptionRequest,
  getAppointmentDetailsRequest,
  removeAppointmentPrescriptionMedicationRequest,
  removeAppointmentPrescriptionRequest,
  updateAppointmentPrescriptionMedicationRequest,
} from 'shared/redux/actions/DoctorAppointmentsActions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import { IPickedFiles } from 'shared/Interfaces/DocumentsInterfaces';
import { Form } from 'react-bootstrap';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDoctorAppointmentDetailsState } from 'shared/redux/src/StatesGetter';
import LocalEventsService from 'shared/services/LocalEventsService';
import dayjs from 'dayjs';
import Prescription from '../../components/appointments/Prescription';
import Medication from '../../components/appointments/Medication';

const AppointmentConclusion = ({
  appointmentId,
  conclusion,
  serverStatus,
  appointmentStep,
  doctor,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isUploading, setIsUploading] = useState(false);
  const [isBlur, setIsBlur] = useState(false);
  const [symptoms, setSymptoms] = useState(conclusion?.symptoms ?? '');
  const [diagnosis, setDiagnosis] = useState(conclusion?.diagnosis ?? '');
  const [knownAllergies, setKnownAllergies] = useState(conclusion?.knownAllergies ?? '');
  const [ongoingTreatments, setOngoingTreatments] = useState(conclusion?.ongoingTreatments ?? '');
  const [treatment, setTreatment] = useState(conclusion?.treatment ?? '');
  const [recommendations, setRecommendations] = useState(conclusion?.recommendations ?? '');
  const [recommendFutureAppointment, setRecommendFutureAppointment] = useState(
    conclusion?.recommendFutureAppointment ?? '',
  );

  const { prescription } = useSelector(getDoctorAppointmentDetailsState, shallowEqual);
  const { patient } = useSelector(getDoctorAppointmentDetailsState, shallowEqual);
  const [addNewMedication, setAddNewMedication] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState<IPickedFiles>([]);
  const hiddenInputRef = useRef(null);

  const [profileAllergies, setProfileAllergies] = useState(patient?.knownAllergies ?? '');

  const [addPrescription, setAddPrescription] = useState(false);

  const [currentMedication, setCurrentMedication] = useState({
    id: '',
    name: '',
    concentration: '',
    administration: '',
    quantity: '',
    duration: '',
    type: '',
  });

  useEffect(() => {
    LocalEventsService.on('uploadInProgress', ({ status }) => {
      setIsUploading(status);
    });

    const selectedFileDataUrl = sessionStorage.getItem('selectedFile');
    if (selectedFileDataUrl) {
      setSelectedFiles(JSON.parse(selectedFileDataUrl));
    }
  }, []);

  const profileKnownAlergies = patient?.knownAllergies ?? '';
  const { serverStatus: statusServer } = useSelector(
    getDoctorAppointmentDetailsState,
    shallowEqual,
  );
  useEffect(() => {
    if (isBlur) {
      if (statusServer !== 'in_progress') {
        dispatch(getAppointmentDetailsRequest({ id: appointmentId }));
      }
      if (
        (appointmentStep === 'videoCall' && statusServer === 'in_progress') ||
        (appointmentStep === 'reviewConclusion' && isBlur) ||
        appointmentStep === 'DetailsTabFolder'
      ) {
        const payloadToAdd = {
          symptoms,
          diagnosis,
          knownAllergies,
          ongoingTreatments,
          treatment,
          recommendations,
          recommend_future_appointment: recommendFutureAppointment !== '' ? 1 : 0,
          isCompleted: false,
        };

        dispatch(
          addAppointmentConclusionRequest({
            toAdd: payloadToAdd,
            id: appointmentId,
            toUpdate: {
              tmpConclusion: null,
            },
          }),
        );
      }
    }
    setIsBlur(false);
  }, [isBlur]);

  const onFileChange = (event) => {
    const chosenFile = event.target.files[0] ?? {};
    const acceptedFiles = [
      'application/pdf',
      'image/jpeg',
      'image/png',
      'image/jpg',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];

    // console.log('chosenFile.name', chosenFile.name);
    if (chosenFile.name !== undefined && !acceptedFiles.includes(chosenFile.type)) {
      Alerts.simpleAlert(t('error'), t('validations.invalidFileType'));
      return;
    }

    if (chosenFile?.name?.length) {
      const reader = new FileReader();

      reader.onload = (e) => {
        // Store the file as a data URL in sessionStorage
        // @ts-ignore
        if (typeof e.target.result === 'string') {
          // @ts-ignore
          sessionStorage.setItem(
            'selectedFile',
            JSON.stringify(
              selectedFiles.concat({
                // @ts-ignore
                originalName: chosenFile.name,
                // fileData: chosenFile,
                // @ts-ignore
                fileData: e.target.result,
                mimeType: chosenFile.type,
                size: chosenFile.size,
                // @ts-ignore
                file: e.target.result,
              }),
            ),
          );
        }

        // Update the state with the selected file object for potential UI updates
        setSelectedFiles((prevState) =>
          prevState.concat({
            originalName: chosenFile.name,
            fileData: chosenFile,
            mimeType: chosenFile.type,
            size: chosenFile.size,
          } as unknown as ConcatArray<never>),
        );
      };

      reader.readAsDataURL(chosenFile); // Read the file as a data URL
    }
  };

  const onDeleteFile = (index) => {
    sessionStorage.setItem(
      'selectedFile',
      JSON.stringify(selectedFiles.filter((r, i) => i !== index)),
    );
    setSelectedFiles((prevState) => prevState.filter((r, i) => i !== index));
  };

  if (serverStatus === 'cancelled') {
    Alerts.actionAlert(t('info'), t('medicalApp.canceledTitle'), t('ok'), () => {
      navigate(`/doctor/appointments/past`);
    });
    return null;
  }

  const medicationFromRecipe = [];
  // @ts-ignore
  if (prescription && prescription?.medications) {
    // @ts-ignore
    prescription?.medications.forEach((medication) => {
      medicationFromRecipe.push({
        // @ts-ignore
        id: medication.id,
        // @ts-ignore
        name: medication.name,
        // @ts-ignore
        concentration: medication.concentration,
        // @ts-ignore
        administration: medication.administration,
        // @ts-ignore
        quantity: medication.quantity,
        // @ts-ignore
        duration: medication.duration,
        // @ts-ignore
        type: medication.type,
      });
    });
  }

  const medicationList = [];
  const medicationListCollectionValue =
    medicationFromRecipe.length > 0 ? medicationFromRecipe : medicationList;
  setTimeout(() => {}, 1000);
  const [medicationListCollection, setMedicationListCollection] = useState(
    medicationListCollectionValue,
  );

  const saveData = (payloadToAdd) => {
    if (serverStatus !== 'call_ended') {
      dispatch(addAppointmentConclusionSuccess({ tmpConclusion: payloadToAdd }));
    } else {
      sessionStorage.removeItem('selectedFile');
      dispatch(
        addAppointmentConclusionRequest({
          toAdd: payloadToAdd,
          files: selectedFiles.map((file) => {
            return file?.fileData;
          }),
          id: appointmentId,
          toUpdate: {
            tmpConclusion: null,
          },
        }),
      );
    }
  };

  const saveAction = (e) => {
    e.preventDefault();
    if (!symptoms || symptoms.trim() === '') {
      Alerts.simpleAlert(t('error'), t('symptomFieldMandatory'));
      return;
    }
    if (!diagnosis || diagnosis.trim() === '') {
      Alerts.simpleAlert(t('error'), t('diagnosisFieldMandatory'));
      return;
    }
    if (!treatment || treatment.trim() === '') {
      Alerts.simpleAlert(t('error'), t('treatmentFieldMandatory'));
      return;
    }
    if (!recommendations || recommendations.trim() === '') {
      Alerts.simpleAlert(t('error'), t('recommendationsFieldMandatory'));
      return;
    }

    // SAVE prescription
    if (prescription?.id && prescription?.medications.length > 0) {
      const payloadToAdd = {
        organizationName: prescription.organization_name,
        organizationAddress: prescription.organization_address,
        series: prescription.series,
        number: prescription.number,
        patientFirstName: prescription.patient_first_name,
        patientLastName: prescription.patient_last_name,
        numericPersonalCode: prescription.numeric_personal_code,
        diagnosis: prescription.diagnosis,
        diagnosisCode: prescription.diagnosis_code,
        isCompleted: true,
      };

      dispatch(
        addAppointmentPrescriptionRequest({
          toAdd: payloadToAdd,
          id: appointmentId,
        }),
      );
    }

    const data = {
      symptoms,
      diagnosis,
      knownAllergies: `${profileAllergies ? `${profileAllergies}, ` : ''}${knownAllergies}`,
      ongoingTreatments,
      treatment,
      recommendations,
      recommend_future_appointment: recommendFutureAppointment !== '' ? 1 : 0,
      isCompleted: true,
    };
    let payloadToAdd;
    if (selectedFiles.length) {
      payloadToAdd = {
        ...data,
        files: selectedFiles.map((file) => {
          return {
            originalName: file.originalName,
            mimeType: file.mimeType,
            size: file.size,
          };
        }),
      };
    } else {
      payloadToAdd = data;
    }

    Alerts.okCancelAlert(
      t('warning'),
      t(
        serverStatus === 'call_ended'
          ? 'validations.sendConclusion'
          : 'validations.preSendConclusion',
      ),
      () => {
        saveData(payloadToAdd);
      },
    );
  };
  const makeAddPrescriptionFalse = () => {
    setAddPrescription(false);
    setTimeout(() => {
      dispatch(getAppointmentDetailsRequest({ id: appointmentId }));
    }, 1000);
  };

  const savePrescriptionAddMedication = () => {
    setTimeout(() => {
      if (localStorage.getItem('REDIRECT_MEDICATION') === 'true') {
        setAddNewMedication(true);
      }
    }, 1000);
  };

  const removePrescription = () => {
    if (location.pathname.indexOf('/doctor/video-call') === 0) {
      setTimeout(() => {
        dispatch(getAppointmentDetailsRequest({ id: appointmentId }));
      }, 1000);
    }

    Alerts.okCancelAlert(t('warning'), t('prescription.removePrescriptionConfirmation'), () => {
      dispatch(
        removeAppointmentPrescriptionRequest({
          id: appointmentId,
        }),
      );
      setTimeout(() => {
        dispatch(getAppointmentDetailsRequest({ id: appointmentId }));
      }, 1000);
      if (location.pathname.indexOf('/doctor/final-consultation') === 0) {
        // navigate(`/doctor/final-consultation/${appointmentId}/prescription`);
      } else if (location.pathname.indexOf('/doctor/consultation-details') === 0) {
        // navigate(`/doctor/consultation-details/${appointmentId}/prescription`);
        // window.location.href = `/doctor/consultation-details/${appointmentId}/prescription`;
      } else {
        setAddPrescription(false);
      }
    });
  };

  const conclusionAddPrescription = () => {
    if (location.pathname.indexOf('/doctor/final-consultation') === 0) {
      navigate(`/doctor/final-consultation/${appointmentId}/prescription`);
    } else if (location.pathname.indexOf('/doctor/consultation-details') === 0) {
      navigate(`/doctor/consultation-details/${appointmentId}/prescription`);
      // window.location.href = `/doctor/consultation-details/${appointmentId}/prescription`;
    } else {
      setAddPrescription(true);
    }
  };

  const conclusionAddMedication = () => {
    setTimeout(() => {
      if (localStorage.getItem('REDIRECT_MEDICATION') === 'false') {
        setAddNewMedication(false);
      }
    }, 1000);
  };

  return (
    <div className="row rowp10">
      <div className="col-12 colp10">
        {addPrescription && !addNewMedication && (
          <Prescription
            prescription={prescription}
            doctor={doctor}
            serverStatus={serverStatus}
            appointmentId={appointmentId}
            setAddPrescription={setAddPrescription}
            addNewMedication={addNewMedication}
            setAddNewMedication={setAddNewMedication}
            makeAddPrescriptionFalse={makeAddPrescriptionFalse}
            removePrescription={removePrescription}
            savePrescriptionAddMedication={savePrescriptionAddMedication}
            setCurrentMedication={setCurrentMedication}
            currentMedication={currentMedication}
          />
        )}

        {addNewMedication && (
          <Medication
            setAddNewMedication={setAddNewMedication}
            prescription={prescription}
            appointmentId={appointmentId}
            conclusionAddMedication={conclusionAddMedication}
            currentMedication={currentMedication}
            setCurrentMedication={setCurrentMedication}
          />
        )}
        {!addPrescription && (
          <form onSubmit={saveAction}>
            <div className="form-input">
              <TextareaAutosize
                name="symptoms"
                id="symptoms"
                value={symptoms}
                className="form-control textarea-autoheight"
                placeholder=" "
                onChange={(event) => {
                  setSymptoms(event.target.value);
                }}
                onBlur={(e) => {
                  setIsBlur(true);
                }}
                minRows={1}
              />
              <label htmlFor="symptoms">{t('symptoms')}*</label>
            </div>
            <div className="form-input">
              <TextareaAutosize
                name="diagnosis"
                id="diagnosis"
                value={diagnosis}
                className="form-control textarea-autoheight"
                placeholder=" "
                onChange={(event) => {
                  setDiagnosis(event.target.value);
                }}
                onBlur={(e) => {
                  setIsBlur(true);
                }}
                minRows={1}
              />
              <label htmlFor="diagnosis">{t('diagnosis')}*</label>
            </div>
            <div className="form-input">
              <input
                type="text"
                name="profileAllergies"
                id="profileAllergies"
                // disabled
                value={profileAllergies}
                onChange={(event) => {}}
                onBlur={(e) => {
                  setIsBlur(true);
                }}
                className="form-control"
                placeholder=" "
              />
              <label htmlFor="profileAllergies">{t('profileAllergies')}</label>
            </div>
            <div className="form-input">
              <input
                type="text"
                name="knownAllergies"
                id="knownAllergies"
                value={knownAllergies}
                onChange={(event) => {
                  setKnownAllergies(event.target.value);
                }}
                onBlur={(e) => {
                  setIsBlur(true);
                }}
                className="form-control"
                placeholder=" "
              />
              <label htmlFor="knownAllergies">{t('knownAllergies')}</label>
            </div>
            <div className="form-input">
              <input
                type="text"
                name="ongoingTreatments"
                id="ongoingTreatments"
                value={ongoingTreatments}
                onChange={(event) => {
                  setOngoingTreatments(event.target.value);
                }}
                onBlur={(e) => {
                  setIsBlur(true);
                }}
                className="form-control"
                placeholder=" "
              />
              <label htmlFor="ongoingTreatments">{t('ongoingTreatments')}</label>
            </div>
            <div className="form-input">
              <TextareaAutosize
                name="treatment"
                id="treatment"
                value={treatment}
                className="form-control textarea-autoheight"
                placeholder=" "
                onChange={(event) => {
                  setTreatment(event.target.value);
                }}
                onBlur={(e) => {
                  setIsBlur(true);
                }}
                minRows={1}
              />
              <label htmlFor="treatment">{t('treatment')}*</label>
            </div>
            <div className="form-textarea">
              <TextareaAutosize
                name="recommendations"
                id="recommendations"
                value={recommendations}
                className="form-control textarea-autoheight"
                placeholder=" "
                onChange={(event) => {
                  setRecommendations(event.target.value);
                }}
                onBlur={(e) => {
                  setIsBlur(true);
                }}
                minRows={5}
              />
              <label htmlFor="recommendations">{t('recommendation')}*</label>
            </div>
            <div className="white-box">
              <div className="title recipe-head">
                <div className="recipe-name">{t('prescription.title')}</div>
                {prescription?.id && serverStatus !== 'completed' && (
                  <div className="recipe-actions">
                    <div
                      className="custom-icon-edit"
                      onClick={() => {
                        conclusionAddPrescription();
                      }}
                    >
                      <CustomIcon
                        className="custom-icon"
                        color={Colors.medicColor}
                        size="24"
                        icon="Edit"
                      />
                    </div>
                    <div
                      className="custom-icon-delete"
                      onClick={() => {
                        removePrescription();
                      }}
                    >
                      <CustomIcon
                        className="custom-icon"
                        color={Colors.darkGrey}
                        size="24"
                        icon="Delete"
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="add-recipe">
                {!prescription ? (
                  <button
                    type="button"
                    className="white-button btn-add-recipe"
                    onClick={() => conclusionAddPrescription()}
                  >
                    <span className="greyText">{t('prescription.addPrescription')}</span>
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.mediumGrey}
                      size="24"
                      icon="Plus"
                    />
                  </button>
                ) : (
                  <div className="subtitle">{dayjs().format('DD MMM YYYY')}</div>
                )}
              </div>
            </div>
            {serverStatus === 'call_ended' && (
              <>
                {!!selectedFiles.length &&
                  selectedFiles.map((doc, index) => {
                    return (
                      <div className="uploaded-box" key={_.uniqueId()}>
                        <div className="uploaded-file">
                          <div className="file-name">
                            <CustomIcon
                              className="custom-icon"
                              color={Colors.darkGrey}
                              size="24"
                              icon="Document"
                            />
                            <span>{doc?.originalName?.slice(0, 20)}</span>
                          </div>
                          <button type="button" onClick={() => onDeleteFile(index)}>
                            <CustomIcon
                              className="custom-icon"
                              color={Colors.mediumGrey}
                              size="24"
                              icon="Delete"
                            />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                {(!selectedFiles.length || selectedFiles.length < 5) && (
                  <div className="upload-files">
                    <Form.Group controlId="uploadConclusion" className="files-box">
                      <Form.Label>
                        <div className="info-file">
                          <div className="title">{t('account.uploadDocument')}</div>
                          <div className="details">{t('account.limitUpload20')}</div>
                        </div>
                        <CustomIcon
                          className="custom-icon"
                          color={Colors.medicColor}
                          size="24"
                          icon="Upload"
                        />
                      </Form.Label>
                      <Form.Control type="file" ref={hiddenInputRef} onChange={onFileChange} />
                      {/* <input type="file" className="form-control" onChange={onFileChange} /> */}
                    </Form.Group>
                  </div>
                )}
                {isUploading && (
                  <>
                    <div>
                      <div>{t('uploadInProgress')}</div>
                    </div>
                  </>
                )}
              </>
            )}

            <div className="custom-control custom-checkbox green-checkbox">
              <input
                className="custom-control-input"
                id="future_appointment"
                type="checkbox"
                checked={recommendFutureAppointment}
                onChange={() => {
                  setRecommendFutureAppointment(!recommendFutureAppointment);
                }}
              />
              <label htmlFor="future_appointment" className="custom-control-label">
                {t('recommendFutureAppointment')}
              </label>
            </div>

            {serverStatus === 'call_ended' && (
              <>
                {prescription?.id && prescription?.medications.length === 0 ? (
                  <>
                    <div className="prescription-warning-text">{t('prescription.notComplete')}</div>
                    <button type="submit" className="green-button btn-send-conclusions" disabled>
                      {t(serverStatus === 'call_ended' ? 'sendConclusions' : 'saveConclusion')}
                    </button>
                  </>
                ) : (
                  <button type="submit" className="green-button btn-send-conclusions">
                    {t(serverStatus === 'call_ended' ? 'sendConclusions' : 'saveConclusion')}
                  </button>
                )}
              </>
            )}
          </form>
        )}
      </div>
    </div>
  );
};

AppointmentConclusion.defaultProps = {
  serverStatus: '',
  conclusion: {
    symptoms: '',
    treatment: '',
    recommendations: '',
    recommendFutureAppointment: false,
  },
};
AppointmentConclusion.propTypes = {
  appointmentId: PropTypes.number.isRequired,
  conclusion: PropTypes.shape({
    symptoms: PropTypes.string,
    treatment: PropTypes.string,
    recommendations: PropTypes.string,
    recommendFutureAppointment: PropTypes.bool,
  }),
  serverStatus: PropTypes.string,
};
export default AppointmentConclusion;
