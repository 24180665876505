import { translatedMonthAndYear } from 'shared/modules/DateTimeUtils';
import dayjs from 'dayjs';

const balanceSelector = (balance) => {
  return {
    totalIncome: balance.total_income / 100,
    monthIncome: balance.month_income / 100,
    percent: balance.percent,
  };
};

const payoutsSelector = (payouts) => {
  // eslint-disable-next-line no-underscore-dangle
  const payoutsArray = payouts._embedded?.items ?? [];
  const data: Array<Record<string, unknown>> = [];

  // eslint-disable-next-line array-callback-return
  payoutsArray.map((row) => {
    data.push({
      id: row?.id,
      status: row.status,
      externalId: row.external_id,
      amount: row.amount / 100,
      createdAt: dayjs(row.created_at).format('YYYY-MM-DD HH:mm'),
      createdAtFormatted: translatedMonthAndYear(row.created_at),
    });
  });

  const { pages, page } = payouts;
  return { noOfPages: pages, currentPage: page, data };
};

const payoutTransactionsSelector = (payout) => {
  // eslint-disable-next-line no-underscore-dangle
  const transactionsArray = payout._embedded?.items ?? [];
  return transactionsArray.map((row) => {
    return {
      id: row?.id,
      status: row.status,
      externalId: row.external_id,
      amount: row.amount / 100,
      amountAsString: row.amount_as_string ?? (row.amount / 100).toString(),
      appointmentId: row.appointment?.id,
      patientName: `${row.user?.first_name} ${row.user?.last_name}`,
      createdAt: dayjs(row.created_at).format('YYYY-MM-DD HH:mm'),
      consultationInvoiceId: row?.consultation_invoice?.media?.id,
      platformFeeInvoiceId: row?.platform_fee_invoice?.media?.id,
      consultationInvoiceStatus: row?.consultation_invoice?.media?.status,
      platformFeeInvoiceStatus: row?.platform_fee_invoice?.media?.status,
      platformFee: row?.platform_fee,
      platformFeeAsString: row?.platform_fee_as_string,
    };
  });
};

const incomeSelector = (income) => {
  // eslint-disable-next-line no-underscore-dangle
  const incomeArray = income._embedded?.items ?? [];
  const data: Array<Record<string, unknown>> = [];

  // eslint-disable-next-line array-callback-return
  incomeArray.map((row) => {
    data.push({
      // id: 200 * Math.random(),
      id: `${row.date_start} - ${row.date_end}`,
      startDate: row.date_start,
      endDate: row.date_end,
      amount: row.total_income / 100,
    });
  });

  const { pages, page } = income;
  return { noOfPages: pages, currentPage: page, data };
};

const incomeTransactionsSelector = (transactions) => {
  // eslint-disable-next-line no-underscore-dangle
  const transactionsArray = transactions._embedded?.items ?? [];

  return transactionsArray.map((transaction) => {
    return {
      id: transaction?.id,
      amount: transaction.amount / 100,
      amountAsString: transaction.amount_as_string,
      platformFee: transaction.platform_fee,
      platformFeeAsString: transaction.platform_fee_as_string,
      amountCaptured: transaction.amount_captured / 100,
      amountCapturedAsString: transaction.amount_captured_as_string,
      amountRefunded: transaction.amount_refunded / 100,
      amountRefundedAsString: transaction.amount_refunded_as_string,
      cardLastDigits: transaction.card_last_digits,
      cardType: transaction.card_brand,
      appointmentId: transaction.appointment?.id,
      patientName: `${transaction.appointment.patient.first_name} ${transaction.appointment.patient.last_name}`,
      createdAt: dayjs(transaction.created_at).format('YYYY-MM-DD HH:mm'),
      createdAtFormatted: translatedMonthAndYear(transaction.created_at),
      consultationInvoiceId: transaction?.consultation_invoice?.media?.id,
      consultationInvoiceStatus: transaction?.consultation_invoice?.media?.status,
      platformFeeInvoiceId: transaction?.platform_fee_invoice?.media?.id,
      platformFeeInvoiceStatus: transaction?.platform_fee_invoice?.media?.status,
    };
  });
};

export {
  payoutsSelector,
  payoutTransactionsSelector,
  balanceSelector,
  incomeSelector,
  incomeTransactionsSelector,
};
