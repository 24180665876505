import 'patient/styles/consultation-details.css';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Form, Modal } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import {
  appointmentCancelRequest,
  appointmentLocalStepsRequest,
  appointmentShareMedicalRecordRequest,
  getPatientAppointmentDetailsRequest,
} from 'shared/redux/actions/PatientAppointmentActions';
import {
  getAuthState,
  getDoctorAppointmentDetailsState,
  getDoctorProfileState,
  getMedicalRecordCategoryState,
  getPatientAppointmentDetailsState,
  getPatientAppointmentState,
  getPatientMedicalRecordsState,
} from 'shared/redux/src/StatesGetter';
import { getPrivateMediaFileRequest } from 'shared/redux/actions/UngroupedActions';
import Alerts from 'shared/components/Alerts';
import Utils from 'shared/modules/Utils';
import {
  getPatientMedicalRecordsRequest,
  patientAddMedicalRecordRequest,
  patientDeleteMedicalRecordRequest,
  patientUpdateMedicalRecordRequest,
} from 'shared/redux/actions/PatientProfileActions';
import { MedicalRecord } from 'shared/Interfaces/DocumentsInterfaces';
import { fetchMedicalRecordCategoryRequest } from 'shared/redux/actions/MedicalRecordCategoryActions';
import DisplayDocumentComponent from 'patient/components/DisplayDocumentComponent';
import FileUploadComponent from 'patient/components/FileUploadComponent';
import SelectDocumentComponent from 'patient/components/SelectDocumentComponent';
import PatientMedicCardButton from 'patient/screens/medicList/PatientMedicCardButton';
import { fetchDoctorProfileRequest } from 'shared/redux/actions/DoctorProfileActions';
import { useTranslation } from 'react-i18next';
import { isAnyAppointmentInProgress } from 'shared/modules/PatientAppointmentUtils';
import i18next from 'i18next';
import dayjs from 'dayjs';
import { translatedFullDate } from '../../../shared/modules/DateTimeUtils';

const ConsultationDetails = () => {
  const { t, i18n } = useTranslation();
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { appointmentId } = useParams();
  const [medicalRecordData, setMedicalRecordData] = useState<MedicalRecord>({
    id: undefined,
    categoryId: undefined,
    title: undefined,
    investigationDate: '',
    files: [],
  });
  const doctor = useSelector(getDoctorProfileState, shallowEqual);
  const listItem = useSelector(getPatientAppointmentDetailsState, shallowEqual);
  const medicalPatientRecords = useSelector(getPatientMedicalRecordsState, shallowEqual);
  const fileTypes = useSelector(getMedicalRecordCategoryState, shallowEqual);
  const { review: savedReview, needReview } = listItem;

  const {
    status: appointmentStatus,
    stickyStatus,
    id: currentAppointmentId,
  } = useSelector(getPatientAppointmentState, shallowEqual);

  const [medicalRecordId, setMedicalRecordId] = useState<number | null | undefined>(undefined);
  const [medicalRecordTitle, setMedicalRecordTitle] = useState<string | null | undefined>('');

  const [medicalRecordCategory, setMedicalRecordCategory] = useState<number | null | undefined>(
    undefined,
  );
  const [selectedFiles, setSelectedFiles] = useState(listItem.sharedFiles ?? []);

  const [showModalMedicalDocuments, setModalMedicalDocumentsShow] = useState(false);
  const hideModal = () => setModalMedicalDocumentsShow(false);
  const showModal = () => setModalMedicalDocumentsShow(true);

  const [modalFilesDisplayed, setModalFilesDisplayed] = useState(false);
  const closeFilesModal = () => setModalFilesDisplayed(false);
  const showFilesModal = () => setModalFilesDisplayed(true);
  const handleMedicalRecordTitleChange = (e) => setMedicalRecordTitle(e.target.value);
  const handleMedicalRecordCategoryChange = (e) => setMedicalRecordCategory(e.target.value);
  const openMedicalRecordFile = (externalId) => dispatch(getPrivateMediaFileRequest(externalId));

  const { patient } = useSelector(getDoctorAppointmentDetailsState, shallowEqual);

  useEffect(() => {
    dispatch(getPatientAppointmentDetailsRequest({ id: appointmentId }));
    dispatch(fetchMedicalRecordCategoryRequest({}));
  }, [i18next.language]);
  const [limr, setLimr] = useState<Record<any, any>>([]);

  useEffect(() => {
    if (!_.isEmpty(listItem)) {
      if (listItem?.doctor?.id !== undefined) {
        dispatch(fetchDoctorProfileRequest({ id: listItem.doctor.id }));
      }
      if (listItem?.patient?.id !== undefined) {
        dispatch(getPatientMedicalRecordsRequest(listItem?.patient?.id));
      }
    }
  }, [listItem]);

  useEffect(() => {
    setLimr(Utils.makeSectionListFormat(listItem?.medicalRecords, 'categoryName'));
  }, [listItem?.medicalRecords]);

  useEffect(() => {
    setMedicalRecordId(medicalRecordData.id);
    setMedicalRecordTitle(medicalRecordData.title);
    setMedicalRecordCategory(medicalRecordData.categoryId);
  }, [medicalRecordData]);

  const goodDuration = (item) => {
    if (item.realDurationAsString !== 0) {
      return item.realDurationAsString;
    }
    return item.duration;
  };

  const updateMedicalRecord = (userId) => {
    dispatch(
      patientUpdateMedicalRecordRequest({
        userId,
        mediaId: medicalRecordId,
        data: {
          title: medicalRecordTitle,
          medicalRecordCategory,
        },
      }),
    );

    hideModal();
  };
  const deleteMedicalRecord = (userId) =>
    Alerts.okCancelAlert(t('warning'), t('medicalProfile.deleteMedicalFile'), () => {
      dispatch(
        patientDeleteMedicalRecordRequest({
          userId,
          mediaId: medicalRecordId,
        }),
      );
      dispatch(getPatientAppointmentDetailsRequest({ id: appointmentId }));
      setLimr(Utils.makeSectionListFormat(listItem?.medicalRecords, 'categoryName'));

      hideModal();
    });

  const deletePatientAppointment = () => {
    Alerts.okCancelAlert(t('warning'), t('validations.cancelAppointment'), () => {
      dispatch(appointmentCancelRequest({ appointmentId: listItem?.id }));
      navigate('/patient/consultations');
    });
  };

  function renderDocumentButton() {
    if (listItem.medicalRecords == null) {
      return (
        <button type="button" className="white-button" onClick={showFilesModal}>
          <CustomIcon className="custom-icon" color={Colors.patientColor} size="24" icon="Plus" />
          {t('addMedicalFiles')}
        </button>
      );
    }
    return (
      <button type="button" className="white-button" onClick={showFilesModal}>
        {t('changeFiles')}
      </button>
    );
  }

  const onCallNowClicked = () => {
    if (!isLoggedIn) {
      Alerts.okCancelAlert(t('info'), t('alerts.youNeedToLogin'), () => {
        navigate('/patient/login');
      });
      return;
    }

    if (isAnyAppointmentInProgress(appointmentStatus, stickyStatus)) {
      Alerts.simpleAlert(t('info'), t('validations.alreadyHasAppointment'));
      return;
    }

    if (doctor?.isAvailableForCallNow) {
      dispatch(
        appointmentLocalStepsRequest({
          doctor,
          type: 'callNow',
          noOfSteps: 4,
          newAppointment: true,
        }),
      );
      navigate('/patient/appointment/specialization');
    }
  };

  const joinAppointment = () =>
    dispatch(
      getPatientAppointmentDetailsRequest({
        id: listItem.id,
        updateLocal: true,
        alsoTwilio: true,
      }),
    );

  const renderButton = () => {
    if (
      !_.isEmpty(listItem) &&
      Utils.rejoinableAppointment(listItem?.serverStatus, listItem?.date, listItem?.duration)
    ) {
      return (
        <button
          type="button"
          onClick={joinAppointment}
          className="resume-consultation-btn green-button"
        >
          <CustomIcon className="custom-icon" color={Colors.white} size="24" icon="Video" />
          {t('appointments.joinAppointment')}
        </button>
      );
    }
    if (!_.isEmpty(listItem) && (listItem?.isFinished || listItem?.serverStatus === 'call_ended')) {
      return (
        <Link to={`/patient/medicProfile/${listItem?.doctor?.id}`}>
          <CustomIcon
            className="custom-icon"
            color={Colors.darkGrey}
            size="24"
            icon="Right_pointer"
          />
        </Link>
      );
    }
    return (
      <>
        <button type="button" onClick={deletePatientAppointment} className="cancel-btn">
          {t('abort')}
        </button>
        <PatientMedicCardButton
          status={listItem?.doctor?.availability}
          onClickEvent={onCallNowClicked}
        />
      </>
    );
  };

  const submitNewFiles = (name, type, investigationDate, pickedFiles) => {
    dispatch(
      patientAddMedicalRecordRequest({
        userId: listItem.patient.id,
        data: {
          title: name,
          // appointmentId,
          medicalRecordCategory: type,
          investigationDate: dayjs(investigationDate).format('YYYY-MM-DD 00:00:00'),
          filesToCreate: pickedFiles.map((file) => {
            return {
              originalName: file.originalName,
              mimeType: file.mimeType,
              size: file.size,
            };
          }),
        },
        files: pickedFiles.map((file) => {
          return file.fileData;
        }),
      }),
    );
  };

  const doSendDocument = () => {
    if (selectedFiles?.length) {
      const requestFiles: Record<string, string>[] = [];
      const uniqueFiles: Record<string, string>[] = [];
      [...selectedFiles, ...listItem.sharedFiles]?.map((fileId) => {
        if (uniqueFiles.indexOf(fileId) === -1) {
          requestFiles.push({ patientMedicalRecord: fileId });
        }
        uniqueFiles.push(fileId);
        return true;
      });

      dispatch(
        appointmentShareMedicalRecordRequest({
          appointmentId,
          body: {
            appointmentPatientMedicalRecords: requestFiles,
          },
        }),
      );
    }
    closeFilesModal();
  };

  const openMedicalRecord = (medicalRecord) => {
    setMedicalRecordData(medicalRecord);
    showModal();
  };

  return (
    <section
      className={`${
        listItem?.isCancelled ? 'consultation-details cancelled' : 'consultation-details'
      }`}
    >
      <div className="title-section">
        <h1>{t('history.details')}</h1>
      </div>
      {listItem?.doctor?.id && (
        <div className="medic-box">
          <div className="info">
            <div className="profile-picture">
              <div
                className="avatar"
                style={
                  listItem?.doctor?.imgUrl
                    ? { backgroundImage: `url(${listItem?.doctor?.imgUrl})` }
                    : { backgroundColor: '#E5E7E8' }
                }
              >
                {!listItem?.doctor?.imgUrl && (
                  <div className="image-placeholder">{`${listItem?.doctor?.firstName?.charAt(
                    0,
                  )}${listItem?.doctor?.lastName?.charAt(0)}`}</div>
                )}
              </div>
            </div>
            <div className="details">
              <div className="name">{listItem?.doctor?.fullNameWithTitle}</div>
              <div className="specialization">{listItem?.doctor?.specialization?.name}</div>
            </div>
          </div>
          <div className="action-buttons">{renderButton()}</div>
        </div>
      )}
      <div className="row rowp10">
        <div className="col-lg-6 col-xl-6 col-md-6 col-12 colp10">
          <div className="subtitle">{t('patient')}</div>
          <div className="patient-box">
            <div
              className="avatar"
              style={
                listItem?.patient?.picture
                  ? { backgroundImage: `url(${listItem?.patient?.picture})` }
                  : { backgroundColor: '#E5E7E8' }
              }
            >
              {!listItem?.patient?.picture && (
                <div className="image-placeholder">{`${listItem?.patient?.firstName?.charAt(
                  0,
                )}${listItem?.patient?.lastName?.charAt(0)}`}</div>
              )}
            </div>
            <div className="info">
              <div className="fullName">{listItem?.patient?.fullNameWithTitle}</div>
              <div className="age">{listItem?.patient?.birthDate}</div>
            </div>
          </div>
          <div className="subtitle">{t('details')}</div>
          <div className="details-box">
            <ListGroup>
              {listItem?.isFinished && (
                <ListGroup.Item>
                  <div className="title">{t('state')}</div>
                  <div className="details">{listItem?.status}</div>
                </ListGroup.Item>
              )}
              <ListGroup.Item>
                <div className="title">{t('dateAndTime')}</div>
                <div className="details">{listItem?.dateFormatted}</div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="title">{t('duration')}</div>
                <div className="details">{goodDuration(listItem)} min</div>
              </ListGroup.Item>
              {listItem?.voucher?.id && (
                <>
                  <ListGroup.Item>
                    <div className="title">{t('settings.voucher')}</div>
                    <div className="details">{listItem?.voucher?.code}</div>
                  </ListGroup.Item>
                </>
              )}
              <ListGroup.Item>
                <div className="title">{t('totalToPay')}</div>
                {listItem?.voucher?.id ? (
                  <div className="details">
                    <span className="gross_price">{listItem?.priceGrossAsString}</span>{' '}
                    {listItem?.priceAsString}
                  </div>
                ) : (
                  <div className="details">{listItem?.priceAsString}</div>
                )}
              </ListGroup.Item>
            </ListGroup>
          </div>
          {['call_ended', 'completed'].indexOf(listItem?.serverStatus) > -1 && (
            <>
              <div className="subtitle">{t('myReview')}</div>
              {needReview ? (
                <Link to="/patient/rating" className="white-button">
                  {t('evaluate')}
                </Link>
              ) : (
                <div className="review-box">
                  <div className="header">
                    <div className="name">{savedReview.anonymized_name}</div>
                    <div className="rating">
                      <CustomIcon
                        className="custom-icon"
                        color={Colors.mediumGrey}
                        size="14"
                        icon="Rating_2"
                      />
                      <i className="rating-icon" />
                      {`${savedReview?.rating} / 5`}
                    </div>
                  </div>
                  <div className="data">
                    {savedReview?.created_at &&
                      dayjs(savedReview?.created_at).format('DD MMM YYYY')}
                  </div>
                  <div className="description">{savedReview?.remarks}</div>
                  {/* <button type="button" className="read-all"> */}
                  {/*   {t('readAll')} */}
                  {/* </button> */}
                </div>
              )}
            </>
          )}
        </div>
        <div className="col-lg-6 col-xl-6 col-md-6 col-12 colp10">
          {!listItem?.isCancelled &&
            !listItem?.isFinished &&
            listItem?.serverStatus !== 'call_ended' && (
              <div>
                <div className="subtitle">{t('sharedDocuments')}</div>
                <div> {renderDocumentButton()}</div>
              </div>
            )}
          {!listItem?.isCancelled && (
            <>
              {listItem?.medicalRecords?.length > 0 && (
                <div className="shared-documents-box">
                  <DisplayDocumentComponent
                    medicalRecords={limr}
                    setMedicalRecordData={setMedicalRecordData}
                    showEditModal={showModal}
                  />
                </div>
              )}
            </>
          )}
          {['call_ended', 'completed'].indexOf(listItem.serverStatus) > -1 && (
            <>
              <div className="subtitle">{t('recommendationMedical')}</div>
              {listItem.conclusion?.recommendations?.length ? (
                <div className="medic-recommendation-box">
                  <div className="details">
                    <div className="item">
                      <div className="title">{t('symptoms')}</div>
                      <div className="description">{listItem.conclusion.symptoms}</div>
                    </div>
                    <div className="item">
                      <div className="title">{t('diagnosis')}</div>
                      <div className="description">{listItem.conclusion.diagnosis}</div>
                    </div>
                    <div className="item">
                      <div className="title">{t('knownAllergies')}</div>
                      <div className="description">{listItem.conclusion.knownAllergies}</div>
                    </div>
                    <div className="item">
                      <div className="title">{t('ongoingTreatments')}</div>
                      <div className="description">{listItem.conclusion.ongoingTreatments}</div>
                    </div>
                    <div className="item">
                      <div className="title">{t('treatment')}</div>
                      <div className="description">{listItem.conclusion.treatment}</div>
                    </div>
                    <div className="item">
                      <div className="title">{t('recommendation')}</div>
                      <div className="description">{listItem.conclusion.recommendations}</div>
                    </div>
                    <div className="item">
                      <div className="title">{t('doctorApp.documents')}</div>
                      {listItem.conclusion.files.map((medicalRecord) => {
                        return (
                          <div key={medicalRecord.id} className="document">
                            <button
                              type="button"
                              onClick={() => openMedicalRecordFile(medicalRecord.id)}
                            >
                              <CustomIcon
                                className="custom-icon document"
                                color={Colors.darkGrey}
                                size="24"
                                icon="Document"
                              />
                              <div className="info">
                                <div className="name">
                                  {Utils.shortenText(
                                    Utils.capitalizeFirstLetter(medicalRecord.name),
                                    30,
                                  )}
                                </div>
                                <div className="data">{medicalRecord.createdAt}</div>
                              </div>
                              <CustomIcon
                                className="custom-icon arrow"
                                color={Colors.darkGrey}
                                size="24"
                                icon="Right_pointer"
                              />
                            </button>
                          </div>
                        );
                      })}
                      {listItem.prescription?.media?.id && (
                        <div className="document">
                          <button
                            type="button"
                            onClick={() => openMedicalRecordFile(listItem.prescription?.media?.id)}
                          >
                            <CustomIcon
                              className="custom-icon document"
                              color={Colors.darkGrey}
                              size="24"
                              icon="Document"
                            />
                            <div className="info">
                              <div className="name">Reteta</div>
                              <div className="data">
                                {translatedFullDate(
                                  listItem.prescription?.media?.created_at,
                                  true,
                                  true,
                                )}
                              </div>
                            </div>
                            <CustomIcon
                              className="custom-icon arrow"
                              color={Colors.darkGrey}
                              size="24"
                              icon="Right_pointer"
                            />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="info-message">
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.mediumGrey}
                    size="24"
                    icon="Warning_2"
                  />
                  {t('noRecommendation')}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <Modal
        show={showModalMedicalDocuments}
        onHide={hideModal}
        animation
        centered
        className="modal-edit-document"
      >
        <Modal.Header closeButton closeLabel={t('close')}>
          <Modal.Title>
            {Utils.capitalizeFirstLetter(Utils.shortenText(medicalRecordData.title, 20))}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <div className="details">
              <div className="subtitle">{t('details')}</div>

              <div className="form-select-label">
                <select
                  required
                  id="type-document"
                  name="type-document"
                  className="custom-select"
                  value={medicalRecordCategory || ''}
                  onChange={handleMedicalRecordCategoryChange}
                >
                  <option value="" disabled hidden>
                    {t('medicalProfile.documentType')}
                  </option>
                  {fileTypes.length &&
                    Utils.makeSelectOptions(fileTypes, 'name', 'id').map((rel) => {
                      return (
                        <option key={rel.value} value={rel.value}>
                          {Utils.capitalizeFirstLetter(rel.label)}
                        </option>
                      );
                    })}
                </select>
                <label htmlFor="type-document">{t('medicalProfile.documentType')}</label>
              </div>

              <div className="form-input">
                <input
                  disabled
                  type="text"
                  id="data-upload"
                  name="data-upload"
                  className="form-control"
                  defaultValue={medicalRecordData.createdAtFullFormatted}
                  placeholder=" "
                />
                <label htmlFor="data-upload">{t('medicalProfile.investigationDate')}</label>
              </div>

              <div className="form-input">
                <input
                  type="text"
                  id="name-document"
                  name="name-document"
                  className="form-control"
                  placeholder=" "
                  value={medicalRecordTitle || ''}
                  onChange={handleMedicalRecordTitleChange}
                />
                <label htmlFor="name-document">{t('medicalProfile.documentName')}</label>
              </div>
            </div>

            <div className="files">
              <div className="subtitle">{t('files')}</div>

              {medicalRecordData?.files.map((file) => {
                return (
                  <div className="uploaded-box" key={file.id}>
                    <div className="uploaded-file">
                      {/* <div className="circle new-file" /> */}
                      <div className="file-name">
                        <CustomIcon
                          className="custom-icon"
                          color={Colors.darkGrey}
                          size="24"
                          icon="Document"
                        />
                        <span
                          className="cursor-pointer"
                          onClick={() => openMedicalRecordFile(file.media.id)}
                        >
                          {file.media.original_file_name ?? ''}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <button
              type="button"
              className="orange-button"
              onClick={() => updateMedicalRecord(listItem.patient.id)}
            >
              {t('saveChanges')}
            </button>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="white-button"
            onClick={() => deleteMedicalRecord(listItem.patient.id)}
          >
            {t('deleteFile')}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalFilesDisplayed}
        onHide={closeFilesModal}
        animation
        centered
        className="modal-medical-documents"
      >
        <Modal.Header closeButton closeLabel={t('close')}>
          <Modal.Title>{t('documents')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="scroll-list">
            <FileUploadComponent
              onFileSubmitted={submitNewFiles}
              fileTypes={fileTypes}
              onDelete={() => {}}
            />
            <SelectDocumentComponent
              medicalRecords={Utils.makeSectionListFormat(medicalPatientRecords, 'categoryName')}
              sharedFiles={listItem.sharedFiles}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="orange-button" onClick={doSendDocument}>
            {t('documentsShared')}
          </button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default ConsultationDetails;
