import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Prescription from '../../components/appointments/Prescription';
import {
  getDoctorAppointmentDetailsState,
  getUserProfileState,
} from '../../../shared/redux/src/StatesGetter';
import {
  getAppointmentDetailsRequest,
  removeAppointmentPrescriptionRequest,
} from '../../../shared/redux/actions/DoctorAppointmentsActions';

const DoctorFinalConsultationPrescription = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { prescription } = useSelector(getDoctorAppointmentDetailsState, shallowEqual);
  const doctor = useSelector(getUserProfileState, shallowEqual);
  const {
    serverStatus,
    id: appointmentId,
    patient,
  } = useSelector(getDoctorAppointmentDetailsState, shallowEqual);
  const [addPrescription, setAddPrescription] = useState(true);
  const [addNewMedication, setAddNewMedication] = useState(false);
  const [prescriptionSaved, setPrescriptionSaved] = useState(false);
  const [currentMedication, setCurrentMedication] = useState({
    id: '',
    name: '',
    concentration: '',
    administration: '',
    quantity: '',
    duration: '',
    type: '',
  });

  const makeAddPrescriptionFalse = () => {
    dispatch(getAppointmentDetailsRequest({ id: appointmentId }));

    setTimeout(() => {
      if (localStorage.getItem('REDIRECT_MEDICATION') === 'true') {
        window.location.href = `/doctor/final-consultation/${appointmentId}/review-conclusions`;
      }
    }, 1000);
  };

  const removePrescription = () => {
    setAddPrescription(false);
    setTimeout(() => {
      window.location.href = `/doctor/final-consultation/${appointmentId}/review-conclusions`;
    }, 1000);
  };

  useEffect(() => {
    if (prescriptionSaved) {
      // setTimeout(() => {
      window.location.href = `/doctor/final-consultation/${prescription.id}/medication`;
      // }, 1000);
    }
  }, [prescription]);

  const savePrescriptionAddMedication = () => {
    dispatch(getAppointmentDetailsRequest({ id: appointmentId }));
    setPrescriptionSaved(true);
  };

  useEffect(() => {
    dispatch(getAppointmentDetailsRequest({ id: appointmentId }));
  }, []);

  return (
    <section className="final-consultation">
      <div className="title-section">
        <div className="small-title-text">{t('reviewFindingsFor')}</div>
        <h1>{`${patient?.firstName} ${patient?.lastName}`}</h1>
      </div>
      <Prescription
        prescription={prescription}
        doctor={doctor}
        serverStatus={serverStatus}
        appointmentId={appointmentId}
        setAddPrescription={setAddPrescription}
        addNewMedication={addNewMedication}
        setAddNewMedication={setAddNewMedication}
        makeAddPrescriptionFalse={makeAddPrescriptionFalse}
        removePrescription={removePrescription}
        savePrescriptionAddMedication={savePrescriptionAddMedication}
        setCurrentMedication={setCurrentMedication}
        currentMedication={currentMedication}
      />
    </section>
  );
};

export default DoctorFinalConsultationPrescription;
