import { AnyAction } from 'redux';
import {
  FETCH_BALANCE_ERROR,
  FETCH_BALANCE_REQUEST,
  FETCH_BALANCE_SUCCESS,
  FETCH_PAYOUT_TRANSACTIONS_ERROR,
  FETCH_PAYOUT_TRANSACTIONS_REQUEST,
  FETCH_PAYOUT_TRANSACTIONS_SUCCESS,
  FETCH_PAYOUTS_ERROR,
  FETCH_PAYOUTS_REQUEST,
  FETCH_PAYOUTS_SUCCESS,
  FETCH_INCOME_ERROR,
  FETCH_INCOME_REQUEST,
  FETCH_INCOME_SUCCESS,
  FETCH_INCOME_TRANSACTIONS_ERROR,
  FETCH_INCOME_TRANSACTIONS_REQUEST,
  FETCH_INCOME_TRANSACTIONS_SUCCESS,
} from 'shared/redux/types/TransactionTypes';

export const fetchBalanceRequest = (payload): AnyAction => ({
  type: FETCH_BALANCE_REQUEST,
  payload,
});

export const fetchBalanceSuccess = (payload): AnyAction => ({
  type: FETCH_BALANCE_SUCCESS,
  payload,
});

export const fetchBalanceError = (payload): AnyAction => ({
  type: FETCH_BALANCE_ERROR,
  payload,
});

export const fetchPayoutsRequest = (payload): AnyAction => ({
  type: FETCH_PAYOUTS_REQUEST,
  payload,
});

export const fetchPayoutsSuccess = (payload): AnyAction => ({
  type: FETCH_PAYOUTS_SUCCESS,
  payload,
});

export const fetchPayoutsError = (payload): AnyAction => ({
  type: FETCH_PAYOUTS_ERROR,
  payload,
});

export const fetchPayoutTransactionsRequest = (payload): AnyAction => ({
  type: FETCH_PAYOUT_TRANSACTIONS_REQUEST,
  payload,
});

export const fetchPayoutTransactionsSuccess = (payload): AnyAction => ({
  type: FETCH_PAYOUT_TRANSACTIONS_SUCCESS,
  payload,
});

export const fetchPayoutTransactionsError = (payload): AnyAction => ({
  type: FETCH_PAYOUT_TRANSACTIONS_ERROR,
  payload,
});

export const fetchIncomeRequest = (payload): AnyAction => ({
  type: FETCH_INCOME_REQUEST,
  payload,
});

export const fetchIncomeSuccess = (payload): AnyAction => ({
  type: FETCH_INCOME_SUCCESS,
  payload,
});

export const fetchIncomeError = (payload): AnyAction => ({
  type: FETCH_INCOME_ERROR,
  payload,
});

export const fetchIncomeTransactionsRequest = (payload): AnyAction => ({
  type: FETCH_INCOME_TRANSACTIONS_REQUEST,
  payload,
});

export const fetchIncomeTransactionsSuccess = (payload): AnyAction => ({
  type: FETCH_INCOME_TRANSACTIONS_SUCCESS,
  payload,
});

export const fetchIncomeTransactionsError = (payload): AnyAction => ({
  type: FETCH_INCOME_TRANSACTIONS_ERROR,
  payload,
});
