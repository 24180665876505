import i18next from 'i18next';
import dayjs from 'dayjs';
import Utils from './Utils';

export const minutesUntil = (timeStr) => {
  const validTime = timeStr ?? dayjs().add(5, 'minute').format('YYYY-MM-DD HH:mm:ss');
  return Utils.timeUntil(validTime); // { days: 0, hours: 0, minutes:4, seconds: 0 }
};

export const translatedDayOfWeek = (dayIndex) => {
  if (dayIndex !== undefined) {
    return i18next.t(`day_${dayIndex + 1}`);
  }
  return '';
};

export const translatedDayOfWeekWithNumber = (dateStr, withDayNumber = false) => {
  const dayIndex = dayjs(dateStr).day();
  const weekDay = i18next.t(`day_${dayIndex}`);
  const day = dayjs(dateStr).format('DD');
  return withDayNumber ? `${weekDay} ${day}` : weekDay;
};

export const translatedMonthAndYear = (dateStr) => {
  const year = dayjs(dateStr).format('YYYY');
  const month = dayjs(dateStr).format('MMMM');
  return `${i18next.t(month)} ${year}`;
};

export const getAge = (dateStr) => {
  if (!dateStr) return '';
  const age = Utils.calculateAgeYMD(dateStr);
  return `${age.years} ${i18next.t('years')}`;
};

export const translatedDate = (dateStr, withComma = false) => {
  const year = withComma ? dayjs(dateStr).format(', YYYY') : dayjs(dateStr).format(' YYYY');
  const month = dayjs(dateStr).format('MMMM');
  const day = dayjs(dateStr).format('DD');
  if (
    day === dayjs().format('DD') &&
    month === dayjs().format('MMMM') &&
    year === dayjs().format('YYYY')
  ) {
    return `${i18next.t('today')} • ${day} ${i18next.t(month)}${year}`;
  }
  if (day === dayjs().add(86400000, 'millisecond').format('DD')) {
    return `${i18next.t('tomorrow')} • ${day} ${i18next.t(month)}${year}`;
  }
  return `${day} ${i18next.t(month)}${year}`;
};

export const translatedFullDate = (dateStr, withDot = true, shortMonth = false) => {
  let lastPart = withDot
    ? dayjs(dateStr).format('YYYY • HH:mm')
    : dayjs(dateStr).format('YYYY HH:mm');
  let month = dayjs(dateStr).format('MMMM');
  if (shortMonth) {
    month = dayjs(dateStr).format('MMM');
  }
  let day = dayjs(dateStr).format('DD');
  if (day === dayjs().format('DD')) {
    day = i18next.t('today');
    month = '';
    lastPart = withDot ? dayjs(dateStr).format(', HH:mm') : dayjs(dateStr).format(' HH:mm');

    return shortMonth ? `${day} ${month} ${lastPart}` : `${day} ${lastPart}`;
  }
  if (day === dayjs().add(86400000, 'millisecond').format('DD')) {
    day = i18next.t('tomorrow');
    month = '';
    lastPart = withDot ? dayjs(dateStr).format(', HH:mm') : dayjs(dateStr).format(' HH:mm');

    return shortMonth ? `${day} ${month} ${lastPart}` : `${day} ${lastPart}`;
  }

  return `${day} ${i18next.t(month)} ${lastPart}`;
};

export const translatedFullDateWithWeekDay = (dateStr) => {
  const dayIndex = dayjs(dateStr).day();
  const weekDay = i18next.t(`day_${dayIndex + 1}`);
  const year = dayjs(dateStr).format('YYYY');
  const month = dayjs(dateStr).format('MMMM');
  const day = dayjs(dateStr).format('DD');
  return `${i18next.t(weekDay)} • ${day} ${i18next.t(month)} ${year}`;
};

export const generateTimeSlots = (length) => {
  return Array.from({ length }, (_, index) => `${index.toString().padStart(2, '0')}`);
};
