import React, { useEffect, useState } from 'react';
import 'patient/styles/appointment-steps.css';
import Form from 'react-bootstrap/Form';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import Utils from 'shared/modules/Utils';
import { IPickedFiles } from 'shared/Interfaces/DocumentsInterfaces';
import Alerts from 'shared/components/Alerts';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

const FileUploadComponent = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { onFileSubmitted, onDelete, fileTypes, noFutureDateAllowed } = props;
  const [pickedFiles, setPickedFiles] = useState<IPickedFiles>([]);
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [investigationDate, setInvestigationDate] = useState('');
  const [uploadFiles, setUploadFiles] = useState(true);

  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    if (
      ['/patient/appointment/medical-documents', '/patient/video-call'].indexOf(location.pathname) >
      -1
    ) {
      if (pickedFiles.length) {
        pickedFiles.forEach((file) => {
          // @ts-ignore
          const fileSize = file.size / 1024 / 1024;
          if (fileSize > 30) {
            setUploadFiles(false);
            setPickedFiles([]);
          } else {
            setUploadFiles(true);
          }
        });
      }
    }
  }, [pickedFiles]);

  const submitNewFile = () => {
    if (noFutureDateAllowed) {
      const nowTimeStamp = dayjs().valueOf();
      const dateTimeStamp = investigationDate
        ? dayjs(investigationDate).valueOf()
        : dayjs().valueOf();
      if (dateTimeStamp > nowTimeStamp) {
        Alerts.simpleAlert(t('error'), t('alerts.futureDate'));
        return;
      }
    }

    if (name === '') {
      Alerts.simpleAlert(t('error'), t('nameFieldMandatory'));
      return;
    }

    if (
      !pickedFiles.length ||
      !pickedFiles[0]?.originalName?.length ||
      investigationDate === '' ||
      !type
    ) {
      Alerts.simpleAlert(t('error'), t('allFieldsMandatory'));
    } else {
      onFileSubmitted(name, type, investigationDate, pickedFiles);
      setPickedFiles([]);
      setName('');
      setType('');
      setInvestigationDate('');
    }
  };

  const handleDateTimePicked = (datetime) => {
    setInvestigationDate(datetime.target.value);
  };

  const uploadNew = (e) => {
    if (e.target.files[0]) {
      // @ts-ignore
      setPickedFiles((prevState) =>
        prevState.concat({
          originalName: e.target.files[0].name as string,
          fileData: e.target.files[0],
          mimeType: e.target.files[0].type as string,
          size: e.target.files[0].size as number,
        } as unknown as ConcatArray<never>),
      );
    }
  };

  const onDeleteFile = (index) => {
    // @ts-ignore
    setPickedFiles((prevState) => prevState.filter((r, i) => i !== index));
    onDelete();
  };

  const fileMimeTypes = [
    'application/x-7z-compressed',
    'image/bmp',
    'image/dcm',
    'image/vnd-ms.dds',
    'image/dicom',
    'application/octet-stream',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/x-emf',
    'image/gif',
    'image/heic',
    'image/heic-sequence',
    'application/x-iso9660-image',
    'image/jpeg',
    'image/jp2',
    'application/pdf',
    'image/png',
    'application/postscript',
    'image/vnd.adobe.photoshop',
    'image/x-psd',
    'application/vnd.rar',
    'application/x-rar-compressed',
    'application/x-tar',
    'application/x-gtar',
    'image/tga',
    'image/x-tga',
    'image/x-targa',
    'image/tiff',
    'image/webp',
    'application/zip',
    'application/x-zip',
    'application/x-zip-compressed',
  ];
  const acceptString = fileMimeTypes.join(', ');

  const acceptedFiles = t('settings.acceptFileInfo');
  const acceptedFilesShort = _.truncate(t('settings.acceptFileInfo'), {
    length: 40,
  });

  return (
    <>
      <div className="upload">
        <div className="subtitle">{t('upload')}</div>
        <Form.Group controlId="formFile" className="files-box">
          <Form.Label>
            <CustomIcon className="custom-icon" color={Colors.darkGrey} size="24" icon="Upload" />
            {t('uploadFile')}
          </Form.Label>
          <Form.Control type="file" accept={acceptString} onChange={uploadNew} />
        </Form.Group>

        <div className="info-message mt-2" onClick={() => setShowInfo(!showInfo)}>
          <CustomIcon
            className="custom-icon"
            color={Colors.mediumGrey}
            size="24"
            icon="Warning_2"
          />
          <div className="info-text-accepted-files">
            <br />
            {`${showInfo ? acceptedFiles : acceptedFilesShort}`}
            <br />
            <br />
          </div>
          <CustomIcon
            className="custom-icon right"
            color={Colors.mediumGrey}
            size="24"
            icon="Down_pointer"
          />
        </div>
      </div>
      {!!pickedFiles.length && uploadFiles && (
        <div className="new-document">
          <div className="subtitle">{t('medicalProfile.newDocument')}</div>
          <div className="uploaded-box">
            {pickedFiles.map((file, index) => {
              return (
                <div className="uploaded-file" key={`file_${file.originalName}`}>
                  <div className="file-name">
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.darkGrey}
                      size="24"
                      icon="Document"
                    />
                    <span>{file.originalName}</span>
                  </div>
                  <button type="button" onClick={() => onDeleteFile(index)}>
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.mediumGrey}
                      size="24"
                      icon="Delete"
                    />
                  </button>
                </div>
              );
            })}
            <div className="form-select-label">
              <select
                required
                id="type-document"
                name="type-document"
                className="custom-select"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="" disabled hidden>
                  {t('medicalProfile.documentType')}
                </option>
                {fileTypes?.length &&
                  fileTypes.map((fType) => (
                    <option key={fType.id} value={fType.id}>
                      {Utils.capitalizeFirstLetter(fType.name)}
                    </option>
                  ))}
              </select>
              <label htmlFor="type-document">{t('medicalProfile.documentType')}</label>
            </div>
            <div className="form-input">
              <input
                type="date"
                id="date-document"
                name="date-document"
                className="form-control"
                placeholder=" "
                onChange={handleDateTimePicked}
                max={dayjs().format('YYYY-MM-DD')}
              />
              <label htmlFor="date-document">{t('medicalProfile.investigationDate')}</label>
            </div>
            <div className="form-input">
              <input
                type="text"
                id="name-document"
                name="name-document"
                className="form-control"
                placeholder=" "
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <label htmlFor="name-document">{t('medicalProfile.documentName')}</label>
            </div>
            <button type="submit" className="orange-button" onClick={submitNewFile}>
              {t('medicalProfile.saveDocument')}
            </button>
          </div>
        </div>
      )}
      {!uploadFiles && (
        <div className="info-message bg-white">
          <CustomIcon
            className="custom-icon"
            color={Colors.mediumGrey}
            size="24"
            icon="Warning_2"
          />
          <div>
            <br />
            {t('fileLimitUpload')}
            <br />
            <br />
          </div>
        </div>
      )}
    </>
  );
};

export default FileUploadComponent;
