import 'doctor/styles/wallet.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getIncomeState } from 'shared/redux/src/StatesGetter';
import {
  fetchIncomeRequest,
  fetchIncomeTransactionsRequest,
} from 'shared/redux/actions/TransactionActions';
import { getEnv } from 'shared/services/EnvService';
import Utils from 'shared/modules/Utils';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';
import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from 'shared/components/LoadingSpinner';
import dayjs from 'dayjs';

const DoctorWalletTabIncome = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: incomeData,
    currentPage: incomeCurrentPage,
    noOfPages: incomeNoOfPages,
  } = useSelector(getIncomeState, shallowEqual);
  const fetchResults = () => dispatch(fetchIncomeRequest({}));

  useEffect(() => {
    fetchResults();
  }, []);

  const loadNextPageIncomeData = () => {
    if (incomeCurrentPage <= incomeNoOfPages) {
      dispatch(
        fetchIncomeRequest({
          page: incomeCurrentPage,
          nextPage: true,
        }),
      );
    }
  };
  const renderItemForIncome = (item) => {
    const {
      startDate = dayjs().format('YYYY-MM-DD HH:mm:ss'),
      endDate = dayjs().format('YYYY-MM-DD HH:mm:ss'),
      amount = 0,
    } = item;
    const dateString = `${dayjs(startDate).format('DD')} - ${dayjs(endDate).format('DD MMM YYYY')}`;
    const onPressIncome = (e) => {
      e.preventDefault();
      dispatch(fetchIncomeTransactionsRequest({ dateStart: startDate, dateEnd: endDate }));
      navigate('/doctor/wallet/unfolded', {
        state: {
          type: 'income',
          data: {
            dateStart: startDate,
            dateEnd: endDate,
            amount: Utils.convertAmount(amount),
            dateString,
          },
        },
      });
    };
    return (
      <div key={item.id.toString()}>
        <div className="subtitle">{dateString}</div>
        <div className="income-box">
          <Link to="/" onClick={onPressIncome}>
            <div className="amount">{`${Utils.convertAmount(amount)} ${getEnv('CURRENCY')}`}</div>
            <div className="estimate">{t('estimated')}</div>
            <CustomIcon
              className="custom-icon arrow"
              color={Colors.darkGrey}
              size="24"
              icon="Right_pointer"
            />
          </Link>
        </div>
      </div>
    );
  };
  return (
    <div className="income-wrapper">
      <InfiniteScroll
        dataLength={incomeData.length}
        next={loadNextPageIncomeData}
        hasMore={incomeCurrentPage <= incomeNoOfPages}
        loader={<LoadingSpinner isLoading />}
        className="income-wrapper"
      >
        {!incomeData.length && <div className="empty-container">{t('wallet.noTransactions')}</div>}

        {incomeData.map((income) => renderItemForIncome(income))}
      </InfiniteScroll>
    </div>
  );
};

DoctorWalletTabIncome.defaultProps = {
  match: {},
  location: {},
};
DoctorWalletTabIncome.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({}),
  }),
};
export default DoctorWalletTabIncome;
