import { AnyAction } from 'redux';
import {
  FETCH_BALANCE_SUCCESS,
  FETCH_INCOME_SUCCESS,
  FETCH_INCOME_TRANSACTIONS_SUCCESS,
  FETCH_PAYOUT_TRANSACTIONS_SUCCESS,
  FETCH_PAYOUTS_SUCCESS,
} from 'shared/redux/types/TransactionTypes';
import { antiDuplication } from 'shared/modules/ArrayUtils';

const BalanceReducer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case FETCH_BALANCE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const PayoutsReducer = (
  state = { data: [], currentPage: 1, noOfPages: undefined },
  action: AnyAction,
) => {
  switch (action.type) {
    case FETCH_PAYOUTS_SUCCESS:
      return {
        data: action.payload.nextPage
          ? antiDuplication([...state.data, ...action.payload.data], 'id')
          : action.payload.data,
        noOfPages: action.payload.noOfPages,
        currentPage: action.payload.nextPage ? state.currentPage + 1 : 2,
      };
    default:
      return state;
  }
};

const PayoutTransactionsReducer = (
  state = { data: [], currentPage: 1, noOfPages: undefined },
  action: AnyAction,
) => {
  switch (action.type) {
    case FETCH_PAYOUT_TRANSACTIONS_SUCCESS:
      return {
        data: action.payload.data,
        noOfPages: action.payload.noOfPages,
        currentPage: action.payload.page,
      };
    default:
      return state;
  }
};

const IncomeReducer = (
  state = { data: [], currentPage: 1, noOfPages: undefined },
  action: AnyAction,
) => {
  switch (action.type) {
    case FETCH_INCOME_SUCCESS:
      return {
        data: action.payload.nextPage
          ? antiDuplication([...state.data, ...action.payload.data], 'id')
          : action.payload.data,
        noOfPages: action.payload.noOfPages,
        currentPage: action.payload.nextPage ? state.currentPage + 1 : 2,
      };
    default:
      return state;
  }
};

const IncomeTransactionsReducer = (
  state = { data: [], currentPage: 1, noOfPages: undefined },
  action: AnyAction,
) => {
  switch (action.type) {
    case FETCH_INCOME_TRANSACTIONS_SUCCESS:
      return {
        data: action.payload.data,
        noOfPages: action.payload.noOfPages,
        currentPage: action.payload.page,
      };
    default:
      return state;
  }
};

export {
  BalanceReducer,
  PayoutsReducer,
  PayoutTransactionsReducer,
  IncomeReducer,
  IncomeTransactionsReducer,
};
