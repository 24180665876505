export const FETCH_BALANCE_REQUEST = 'FETCH_BALANCE_REQUEST';
export const FETCH_BALANCE_SUCCESS = 'FETCH_BALANCE_SUCCESS';
export const FETCH_BALANCE_ERROR = 'FETCH_BALANCE_ERROR';

export const FETCH_PAYOUTS_REQUEST = 'FETCH_PAYOUTS_REQUEST';
export const FETCH_PAYOUTS_SUCCESS = 'FETCH_PAYOUTS_SUCCESS';
export const FETCH_PAYOUTS_ERROR = 'FETCH_PAYOUTS_ERROR';

export const FETCH_PAYOUT_TRANSACTIONS_REQUEST = 'FETCH_PAYOUT_TRANSACTIONS_REQUEST';
export const FETCH_PAYOUT_TRANSACTIONS_SUCCESS = 'FETCH_PAYOUT_TRANSACTIONS_SUCCESS';
export const FETCH_PAYOUT_TRANSACTIONS_ERROR = 'FETCH_PAYOUT_TRANSACTIONS_ERROR';

export const FETCH_INCOME_REQUEST = 'FETCH_INCOME_REQUEST';
export const FETCH_INCOME_SUCCESS = 'FETCH_INCOME_SUCCESS';
export const FETCH_INCOME_ERROR = 'FETCH_INCOME_ERROR';

export const FETCH_INCOME_TRANSACTIONS_REQUEST = 'FETCH_INCOME_TRANSACTIONS_REQUEST';
export const FETCH_INCOME_TRANSACTIONS_SUCCESS = 'FETCH_INCOME_TRANSACTIONS_SUCCESS';
export const FETCH_INCOME_TRANSACTIONS_ERROR = 'FETCH_INCOME_TRANSACTIONS_ERROR';
