import 'doctor/styles/wallet.css';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import PropTypes from 'prop-types';
import { fetchIncomeRequest, fetchPayoutsRequest } from 'shared/redux/actions/TransactionActions';
import { Nav } from 'react-bootstrap';
import DoctorWalletTabIncome from 'doctor/components/wallet/DoctorWalletTabIncome';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DoctorWallet = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tab = 'income' } = useParams();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(tab);

  useEffect(() => {
    if (activeTab === 'income') {
      dispatch(fetchIncomeRequest({}));
      return;
    }
    dispatch(fetchPayoutsRequest({}));
  }, [activeTab]);

  const changeTab = (k) => {
    setActiveTab(k);
    navigate(`/doctor/wallet/${k}`);
  };
  return (
    <section className="wallet">
      <div className="title-section">
        <h1>{t('wallet.header')}</h1>
      </div>
      <DoctorWalletTabIncome />
    </section>
  );
};

DoctorWallet.defaultProps = {
  match: {},
  location: {},
};
DoctorWallet.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({}),
  }),
};
export default DoctorWallet;
