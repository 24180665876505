import 'shared/styles/unsupported-browser.css';
import 'shared/styles/gate.css';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import FooterSection from 'shared/components/FooterSection';
import HeaderMenu from 'shared/components/HeaderMenu';
import HeaderMenuMobile from 'shared/components/HeaderMenuMobile';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import chromeLogo from 'shared/assets/images/logos_chrome.svg';
import firefoxLogo from 'shared/assets/images/logos_firefox.svg';
import edgeLogo from 'shared/assets/images/logos_microsoft-edge.svg';
import React from 'react';

const UnsupportedBrowser = ({ suggestOtherBrowser, isDoctor }) => {
  const { t } = useTranslation();

  return (
    <div className="unsupported-browser-page">
      <Helmet>
        <title>{t('unsupportedBrowserPage.titlePage')}</title>
        <meta
          name="description"
          content="Ringdoc este o aplicatie de telemedicina pentru consultatii online prin care poti vorbi cu un medic specialist fara programare de oriunde; Home Page"
        />
      </Helmet>

      {isDoctor && <HeaderMenuMobile />}

      <div className="container-fluid">
        <div className="row">
          <HeaderMenu />

          <main className="container">
            <div className="box">
              <section className="title">
                <h2 className={`font-weight-bold ${suggestOtherBrowser ? 'text-center' : ''}`}>
                  {suggestOtherBrowser
                    ? t('unsupportedBrowserPage.title1')
                    : t('unsupportedBrowserPage.title2')}
                </h2>
              </section>
              {!suggestOtherBrowser && (
                <>
                  <section className="boxes">
                    <div className="box-number">
                      <div className="circle one">
                        <span>1</span>
                      </div>
                      <div className="description">
                        {t('unsupportedBrowserPage.downloadApp')}
                        <div className="apps-button vertical-buttons">
                          <a
                            target="_blank"
                            href={
                              isDoctor
                                ? 'https://apps.apple.com/us/app/ringdoc-medic/id1574488917'
                                : 'https://apps.apple.com/us/app/ringdoc/id1574488837'
                            }
                            rel="noreferrer"
                          >
                            <i className="app-store-icon cursor-pointer" />
                          </a>
                          <br />
                          <a
                            target="_blank"
                            href={
                              isDoctor
                                ? 'https://play.google.com/store/apps/details?id=ro.ascendnet.alodoc.doctor.ro'
                                : 'https://play.google.com/store/apps/details?id=ro.ascendnet.alodoc.patient.ro'
                            }
                            rel="noreferrer"
                          >
                            <i className="google-play-icon cursor-pointer" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="box-number">
                      <div className="circle two">
                        <span>2</span>
                      </div>
                      <div className="description">
                        {t('unsupportedBrowserPage.selectSpecialization')}
                      </div>
                    </div>
                    <div className="box-number">
                      <div className="circle three">
                        <span>3</span>
                      </div>
                      <div className="description">
                        {t('unsupportedBrowserPage.makeAppointment')}
                      </div>
                    </div>
                    <div className="box-number">
                      <div className="circle four">
                        <span>4</span>
                      </div>
                      <div className="description">{t('unsupportedBrowserPage.makePayment')}</div>
                    </div>
                    <div className="box-number">
                      <div className="circle five">
                        <span>5</span>
                      </div>
                      <div className="description">
                        {t('unsupportedBrowserPage.getRecommendation')}
                      </div>
                    </div>
                  </section>
                  <section className="gift">
                    <i className="gift-icon" />
                    <span>
                      {t('unsupportedBrowserPage.subtitle-promotion1')}
                      {` `}
                    </span>
                    <span className="subtitle-promotion-value">
                      {t('unsupportedBrowserPage.subtitle-promotion2')}
                    </span>
                    <br />
                    <span>{t('unsupportedBrowserPage.subtitle-promotion3')}</span>
                  </section>
                </>
              )}
              {suggestOtherBrowser && (
                <section className="supported-browsers">
                  <p>{t('unsupportedBrowserPage.subtitle1')}</p>
                  <p>{t('unsupportedBrowserPage.subtitle2')}</p>

                  <div
                    className="browser-box mb-3"
                    onClick={() => window.open(`https://www.google.com/chrome`, '_self')}
                  >
                    <div className="cta-data">
                      <img src={chromeLogo} alt="Google Chrome" />
                      <h2>{t('unsupportedBrowserPage.installChrome')}</h2>
                    </div>

                    <CustomIcon
                      className="custom-icon arrow"
                      color={Colors.darkGrey}
                      size="24"
                      icon="Right_pointer"
                    />
                  </div>
                  <div
                    className="browser-box mb-3"
                    onClick={() => window.open(`https://www.mozilla.org/firefox/new`, '_self')}
                  >
                    <div className="cta-data">
                      <img src={firefoxLogo} alt="Google Chrome" />
                      <h2>{t('unsupportedBrowserPage.installFirefox')}</h2>
                    </div>

                    <CustomIcon
                      className="custom-icon arrow"
                      color={Colors.darkGrey}
                      size="24"
                      icon="Right_pointer"
                    />
                  </div>
                  <div
                    className="browser-box"
                    onClick={() => window.open(`https://www.microsoft.com/edge`, '_self')}
                  >
                    <div className="cta-data">
                      <img src={edgeLogo} alt="Google Chrome" />
                      <h2>{t('unsupportedBrowserPage.installEdge')}</h2>
                    </div>

                    <CustomIcon
                      className="custom-icon arrow"
                      color={Colors.darkGrey}
                      size="24"
                      icon="Right_pointer"
                    />
                  </div>
                </section>
              )}
            </div>

            {suggestOtherBrowser && (
              <div className="gate-page">
                <div className="download-app">
                  <p>{t('unsupportedBrowserPage.availableOnMobile')}</p>
                  <div className="apps-button">
                    <a
                      target="_blank"
                      href={
                        isDoctor
                          ? 'https://apps.apple.com/us/app/ringdoc-medic/id1574488917'
                          : 'https://apps.apple.com/us/app/ringdoc/id1574488837'
                      }
                      rel="noreferrer"
                    >
                      <i className="app-store-icon cursor-pointer" />
                    </a>
                    <div className="spacer" />
                    <a
                      target="_blank"
                      href={
                        isDoctor
                          ? 'https://play.google.com/store/apps/details?id=ro.ascendnet.alodoc.doctor.ro'
                          : 'https://play.google.com/store/apps/details?id=ro.ascendnet.alodoc.patient.ro'
                      }
                      rel="noreferrer"
                    >
                      <i className="google-play-icon cursor-pointer" />
                    </a>
                  </div>
                </div>
              </div>
            )}
          </main>

          {/* <FooterSection /> */}
        </div>
      </div>
    </div>
  );
};

export default UnsupportedBrowser;
