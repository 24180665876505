import 'doctor/styles/dashboard.css';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Form, ListGroup } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import {
  fetchMySpecializationsRequest,
  fetchUserProfileRequest,
  setAvailabilityRequest,
} from 'shared/redux/actions/UserProfileActions';
import {
  getAuthState,
  getBalanceState,
  getDoctorAppointmentState,
  getDoctorCallsStackState,
  getDoctorTodayAppointmentsState,
  getSharedState,
  getUserProfileState,
} from 'shared/redux/src/StatesGetter';
import Utils from 'shared/modules/Utils';
import {
  fetchNotificationsSettingsRequest,
  fetchPushesRequest,
} from 'shared/redux/actions/NotificationActions';
import { fetchBalanceRequest, fetchIncomeRequest } from 'shared/redux/actions/TransactionActions';
import { fetchCountriesRequest } from 'shared/redux/actions/CountryActions';
import { fetchCurrencyRequest } from 'shared/redux/actions/CurrencyActions';
import { getSpecializationsRequest } from 'shared/redux/actions/SpecializationActions';
import {
  doctorAcceptsCallRequest,
  doctorAppointmentUpdatesRequest,
  doctorRejectsCallRequest,
  fetchDoctorTodayAppointmentsRequest,
  fetchDoctorUpcomingAppointmentsRequest,
  getAppointmentDetailsRequest,
  getCallSpecialistInfoRequest,
  removeDoctorCallsStackRequest,
  updateDoctorCallsStackRequest,
} from 'shared/redux/actions/DoctorAppointmentsActions';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';
import mainScreenSelector from 'shared/redux/selector/DoctorMainScreenSelector';
import { locallyAddDate } from 'shared/redux/actions/DoctorTimetableActions';
import Avatar from 'shared/components/Avatar';
import { useTranslation } from 'react-i18next';
import TimerComponent from 'shared/components/TimerComponent';
import Alerts from 'shared/components/Alerts';
import { updateSharedStateAction } from 'shared/redux/actions/UngroupedActions';
import i18next from 'i18next';
import dayjs from 'dayjs';
import { getPatientAppointmentDetailsRequest } from '../../../shared/redux/actions/PatientAppointmentActions';
import PatientPushNotifier from '../../../shared/modules/PatientPushNotifier';
import DoctorPushNotifier from '../../../shared/modules/DoctorPushNotifier';

const DoctorHome = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { timeOptionsAsArray } = useSelector(getSharedState, shallowEqual);
  const { isLoggedIn, isProfileCompleted } = useSelector(getAuthState, shallowEqual);
  const profileData = useSelector(getUserProfileState, shallowEqual);
  const { monthIncome } = useSelector(getBalanceState, shallowEqual);
  const { data: appointmentsStack, missedCalls } = useSelector(
    getDoctorCallsStackState,
    shallowEqual,
  );
  const { isAvailableForCallNow = false, name = '', isResident = false } = profileData;
  const [onlineStatus, setOnlineStatus] = useState(isAvailableForCallNow);
  const {
    data: todayAppointments,
    callNowAppointment,
    nextScheduledAppointment,
  } = useSelector(getDoctorTodayAppointmentsState, shallowEqual);

  useEffect(() => {
    if (profileData.isPharmacist) {
      window.location.href = '/doctor/recommend';
    }
  }, [profileData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isProfileCompleted === false) {
        navigate('/doctor/register-step-personal-data');
      }
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, [isProfileCompleted]);

  useEffect(() => {
    setOnlineStatus(isAvailableForCallNow);
  }, [isAvailableForCallNow]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchPushesRequest({}));
      dispatch(fetchNotificationsSettingsRequest({}));
      dispatch(fetchBalanceRequest({}));
      dispatch(fetchUserProfileRequest({}));
      dispatch(fetchMySpecializationsRequest({}));
      dispatch(fetchDoctorTodayAppointmentsRequest({}));
      dispatch(fetchIncomeRequest({}));
      dispatch(locallyAddDate({}));
      dispatch(getCallSpecialistInfoRequest({}));
    }
    dispatch(fetchCountriesRequest({}));
    dispatch(fetchCurrencyRequest({}));
    dispatch(getSpecializationsRequest({}));
  }, [isLoggedIn]);

  const toggleOnlineStatus = () => {
    dispatch(setAvailabilityRequest({ isAvailableForCallNow: !onlineStatus }));
  };
  const { routes } = mainScreenSelector(isResident);
  const goToAppointment = (appointmentId) => {
    dispatch(getAppointmentDetailsRequest({ id: appointmentId }));
    navigate(`/doctor/consultation-details/${appointmentId}`, {
      state: {
        templateType: 'future',
      },
    });
  };
  const joinAppointment = (e, item) => {
    e.preventDefault();
    if (!Utils.rejoinableAppointment(item?.serverStatus, item?.date, item?.duration)) {
      Alerts.actionAlert(t('info'), t('alerts.tooLate'), 'Ok', () =>
        dispatch(fetchDoctorUpcomingAppointmentsRequest({})),
      );
      return;
    }
    dispatch(
      getAppointmentDetailsRequest({
        id: item?.id,
        updateLocal: true,
        alsoTwilio: true,
      }),
    );
  };
  const callSpecialistGiveTime = (data) => {
    Alerts.doctorIncomingCallSpecialistAlert(
      `${t('specialistConsultation')} ${data.appointment.specialization}`,
      data.appointment?.priceAsString,
      data.appointment?.timeStart,
      timeOptionsAsArray,
      (timeEstimation) => {
        if (!timeEstimation) {
          return;
        }
        dispatch(
          doctorAcceptsCallRequest({
            // @ts-ignore
            data: { appointmentId: data.id, bidTime: timeEstimation },
          }),
        );
        dispatch(
          updateDoctorCallsStackRequest([
            {
              id: data.id,
              ...data,
              state: 'pendingResult',
            },
          ]),
        );
      },
      () => {
        dispatch(doctorRejectsCallRequest({ id: data.id }));
        dispatch(removeDoctorCallsStackRequest({ id: data.id }));

        dispatch(
          updateSharedStateAction({
            showWaitingTime: false,
            showNextAppointment: false,
            isNotificationInProgress: false,
          }),
        );

        dispatch(getCallSpecialistInfoRequest({}));
      },
    );
  };
  const callNowAccept = () => {
    dispatch(
      doctorAppointmentUpdatesRequest({
        patient: callNowAppointment.patient,
        id: callNowAppointment.id,
        appointmentId: callNowAppointment.id,
        tmpConclusion: null,
      }),
    );
    dispatch(
      updateSharedStateAction({
        showWaitingTime: true,
        showNextAppointment: false,
      }),
    );
  };
  const callNowDecline = (appointmentId) => {
    dispatch(doctorRejectsCallRequest({ id: appointmentId }));
    dispatch(
      updateSharedStateAction({
        showWaitingTime: false,
        showNextAppointment: false,
        isNotificationInProgress: false,
      }),
    );
  };

  const renderSubTitle = (appointment) => {
    if (appointment?.medicalRecords?.length) {
      return (
        <div className="year-files">
          {Utils.calculateAge(appointment?.patient?.birthDate) > 0 &&
            `${Utils.calculateAge(appointment?.patient?.birthDate)} ${t('years')} • `}
          <CustomIcon className="custom-icon" color={Colors.mediumGrey} size="15" icon="Document" />
          {t('attachedFiles')}
        </div>
      );
    }
    if (Utils.calculateAge(appointment?.patient?.birthDate) > 0) {
      return (
        <div className="year-files">{`${Utils.calculateAge(appointment?.patient?.birthDate)} ${t(
          'years',
        )}`}</div>
      );
    }
    return null;
  };

  const renderNextScheduledAppointment = () => {
    if (!nextScheduledAppointment) return null;
    const {
      id,
      patient: nextAppointmentPatient,
      time,
      specializationName,
      service,
    } = nextScheduledAppointment;
    const {
      picture,
      firstName: patientFirstName,
      lastName: patientLastName,
    } = nextAppointmentPatient;
    return (
      <>
        <div className="subtitle">{t('nextScheduledAppointment')}</div>
        <div className="next-schedule-box">
          <div className="hour-box">{time}</div>
          <div onClick={() => goToAppointment(id)} className="patient-box cursor-pointer">
            <div className="avatar-section">
              <Avatar
                size={38}
                imageURL={picture}
                firstName={patientFirstName}
                lastName={patientLastName}
              />
              <CustomIcon
                className="custom-icon"
                color={Colors.darkGrey}
                size="24"
                icon="Right_pointer"
              />
            </div>
            <div className="fullName">{`${patientFirstName} ${patientLastName}`}</div>
            <div className="requested-specialization">
              {specializationName} • {Utils.capitalizeFirstLetter(t(service.slug))}
            </div>
            {renderSubTitle(nextScheduledAppointment)}
          </div>
        </div>
        <div
          onClick={(e) => joinAppointment(e, nextScheduledAppointment)}
          className={`cursor-pointer green-button start-consultation-btn ${
            !Utils.rejoinableAppointment(
              nextScheduledAppointment?.serverStatus,
              nextScheduledAppointment?.date,
              nextScheduledAppointment?.duration,
            )
              ? 'not-ready'
              : ''
          }`}
        >
          <CustomIcon
            className="custom-icon"
            color={
              !Utils.rejoinableAppointment(
                nextScheduledAppointment?.serverStatus,
                nextScheduledAppointment?.date,
                nextScheduledAppointment?.duration,
              )
                ? Colors.mediumGrey
                : Colors.white
            }
            size="24"
            icon="Video"
          />
          {Utils.timeUntil(nextScheduledAppointment.date).hours === 0 ? (
            <>
              {t('appointments.startAppointmentIn')}
              <div className="timer">
                <TimerComponent
                  action={() => {}}
                  endTime={nextScheduledAppointment?.date}
                  onTimeAboutToEnd={() => {}}
                />
              </div>
            </>
          ) : (
            t('appointments.startAppointment')
          )}
        </div>
      </>
    );
  };

  const renderJoinDoctorAppointmentButton = (appointment) => {
    return (
      <div
        onClick={(e) => joinAppointment(e, appointment)}
        className={`cursor-pointer green-button start-consultation-btn ${
          !Utils.rejoinableAppointment(
            appointment?.serverStatus,
            appointment?.date,
            appointment?.duration,
          )
            ? 'not-ready'
            : ''
        }`}
      >
        <CustomIcon
          className="custom-icon"
          color={
            !Utils.rejoinableAppointment(
              appointment?.serverStatus,
              appointment?.date,
              appointment?.duration,
            )
              ? Colors.mediumGrey
              : Colors.white
          }
          size="24"
          icon="Video"
        />
        {Utils.timeUntil(appointment.date).hours === 0 ? (
          <>
            {t('appointments.startAppointmentIn')}
            <div className="timer">
              <TimerComponent
                action={() => {}}
                endTime={appointment.date}
                onTimeAboutToEnd={() => {}}
              />
            </div>
          </>
        ) : (
          t('appointments.startAppointment')
        )}
      </div>
    );
  };

  const joinAppointmentModal = (e, item) => {
    e.preventDefault();

    const dataPushPayload = {
      type: 'consultation_start_now_for_doctor',
      metadata: {
        appointment: {
          id: item?.id,
          timeStart: item?.startTime,
          timeEnd: item?.endTime,
          timeEndWithPause: item?.timeEndWithPause,
        },
      },
      body: t('patientIsWaiting', {
        firstName: item.patient.firstName,
        lastName: item.patient.lastName,
      }),
      title: 'Consultația a început',
      uuid: '',
    };
    DoctorPushNotifier.onForegroundPushReceived({
      data: { ...dataPushPayload, type: 'consultation_start_now_for_doctor' },
    });
  };
  const renderCallNowAppointment = () => {
    if (!callNowAppointment) return null;
    const {
      id,
      patient: nextAppointmentPatient,
      date,
      serverStatus: callNowServerStatus,
      duration,
    } = callNowAppointment;
    const {
      picture,
      firstName: patientFirstName,
      lastName: patientLastName,
    } = nextAppointmentPatient;

    if (callNowServerStatus !== 'searching_for_doctor') {
      return (
        <>
          {/* <Link className="logo cursor-pointer" to="/doctor/video-call"> */}
          <div className="subtitle">{t('waitingCall')}</div>
          <div className="next-fast-box">
            <div className="info">
              <div className="title">
                <div>{t('waitingCall')}</div>
                <div className="description">{`${patientFirstName} ${patientLastName}`}</div>
              </div>

              {Utils.timeUntil(date).hours === 0 ? (
                <div className="timer">
                  <TimerComponent action={() => {}} endTime={date} onTimeAboutToEnd={() => {}} />
                  <p>&nbsp;{t('min')}</p>
                </div>
              ) : (
                <div
                  onClick={(e) => joinAppointmentModal(e, callNowAppointment)}
                  className="cursor-pointer green-button home-start-consultation-btn mt-0"
                >
                  <CustomIcon className="custom-icon" color={Colors.white} size="24" icon="Video" />
                  <span>{t('appointments.startAppointmentNow')}</span>
                </div>
              )}
            </div>
          </div>
          {/* </Link> */}
        </>
      );
    }

    return (
      <>
        <div className="subtitle">{t('answerNow')}</div>
        <div className="call-now-box">
          <div className="patient-box">
            <div className="avatar-section">
              <Avatar
                size={38}
                imageURL={picture}
                firstName={patientFirstName}
                lastName={patientLastName}
              />
            </div>
            <div className="fullName">{`${patientFirstName} ${patientLastName}`}</div>
            {renderSubTitle(nextScheduledAppointment)}
          </div>
          <div className="actions">
            <button className="cancel-button" type="button" onClick={() => callNowDecline(id)}>
              {`${i18next.t('decline')}`}
            </button>
            <button className="accept-button" type="button" onClick={() => callNowAccept()}>
              <CustomIcon className="custom-icon" color={Colors.white} size="24" icon="Video" />
              {`${i18next.t('takeCall')}`}
            </button>
          </div>
        </div>
      </>
    );
  };

  const renderWaitingList = () => {
    if (!appointmentsStack?.length) return null;

    return (
      <>
        <div className="subtitle">
          {t('fastAppointmentsWaiting', {
            count: appointmentsStack.length,
          })}
        </div>
        {appointmentsStack?.map((item) => {
          return (
            <div className="next-fast-box" key={item.id}>
              <div className="info">
                <div className="fullName">
                  {item.patient?.firstName
                    ? `${item.patient?.firstName} ${item.patient?.lastName}`
                    : t('waitingForPatientProfile')}
                </div>
                {item?.state === 'pendingResult' ? (
                  <div className="status-badge">{t(item.state)}</div>
                ) : (
                  <div className="timer">
                    {item.appointment?.timeStart &&
                    dayjs().valueOf() < dayjs(item.appointment?.timeStart).valueOf() ? (
                      <>
                        <TimerComponent
                          action={() => {}}
                          endTime={item.appointment?.timeStart}
                          onTimeAboutToEnd={() => {}}
                        />
                        <p>&nbsp;{t('min')}</p>
                      </>
                    ) : null}
                  </div>
                )}
              </div>
              {item?.state === 'waitingForAction' && (
                <button
                  type="button"
                  className="green-button"
                  onClick={() => callSpecialistGiveTime(item)}
                >
                  {t('waitingForAction')}
                </button>
              )}
            </div>
          );
        })}
      </>
    );
  };

  const renderTodayAppointmentList = () => {
    if (!todayAppointments.length) return null;

    return (
      <>
        <div className="subtitle">{t('todayAppointments')}</div>
        {todayAppointments?.map((appointment) => {
          const { id, patient: appointmentPatient, time, specializationName } = appointment;
          const {
            picture,
            firstName: patientFirstName,
            lastName: patientLastName,
          } = appointmentPatient;
          return (
            <div className="next-schedule-box" key={id}>
              <div className="hour-box">{time}</div>
              <div onClick={() => goToAppointment(id)} className="patient-box cursor-pointer">
                <div className="avatar-section">
                  <Avatar
                    size={38}
                    imageURL={picture}
                    firstName={patientFirstName}
                    lastName={patientLastName}
                  />
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.darkGrey}
                    size="24"
                    icon="Right_pointer"
                  />
                </div>
                <div className="fullName">{`${patientFirstName} ${patientLastName}`}</div>
                <div className="requested-specialization">{specializationName}</div>
                {renderSubTitle(appointment)}
              </div>
            </div>
          );
        })}
      </>
    );
  };
  // Alerts.doctorIncomingCallSpecialistAlert();
  return (
    <section className="dashboard">
      <div className="title-section">
        <div className="small-title-text">{t('doctorApp.homeScreenWelcome')},</div>
        <h1>{name}</h1>
      </div>
      <div className="row rowp10">
        <div className="col-lg-6 col-xl-6 col-md-6 col-12 colp10">
          <ListGroup className="switch-box">
            <ListGroup.Item>
              <Form.Check
                checked={onlineStatus}
                onChange={toggleOnlineStatus}
                type="switch"
                className="custom-switch green-switch"
                id="specialized"
                label={t('availableForCalls')}
              />
            </ListGroup.Item>
          </ListGroup>
          <div className="income-received-box">
            <div className="amount-this-month">
              <div className="value">{monthIncome ?? 0} RON</div>
              <span>{t('monthlyIncome')}</span>
            </div>
            <div className="missed-calls">
              <div className="value">{missedCalls ?? 0}</div>
              {t('missedCalls')}
            </div>
          </div>
          {renderWaitingList()}
          {renderTodayAppointmentList()}
        </div>
        <div className="col-lg-6 col-xl-6 col-md-6 col-12 colp10 right-column-dashboard">
          {renderCallNowAppointment()}
          {renderNextScheduledAppointment()}
          <div className="subtitle">{t('shortcuts')}</div>
          <ListGroup className="quick-access-box">
            {routes
              ?.filter((row) => row?.data?.length)
              .map((section) => (
                <div key={`shortcut_${section?.title}`}>
                  {section.data?.map((item) => {
                    return (
                      <ListGroup.Item key={item.title}>
                        <Link to={item.onclick}>
                          <div className="title">
                            <div>{item.title}</div>
                            {item.subtitle.length > 0 && (
                              <div className="description">{item.subtitle}</div>
                            )}
                          </div>
                          <CustomIcon
                            className="custom-icon"
                            color={item.iconColor}
                            size="28"
                            icon={item.icon}
                          />
                        </Link>
                      </ListGroup.Item>
                    );
                  })}
                </div>
              ))}
          </ListGroup>
        </div>
      </div>
    </section>
  );
};

export default DoctorHome;
