import { NavLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { slide as Menu } from 'react-burger-menu';
import CustomIcon from 'shared/components/CustomIcon';
import Fonts from 'shared/themes/Fonts';
import Colors from 'shared/themes/Colors';
import LocalEventsService from 'shared/services/LocalEventsService';
import React, { Fragment, useState } from 'react';
import { isTwilioScreen } from 'shared/services/EnvService';
import i18next from 'i18next';
import LanguageNavSelector from 'patient/components/LanguageNavSelector';
import { shallowEqual, useSelector } from 'react-redux';
import _ from 'lodash';
import { getUserProfileState } from '../../shared/redux/src/StatesGetter';

const DoctorTopMenu = ({ routes, unseen, isMobile }) => {
  const navigate = useNavigate();
  const profileData = useSelector(getUserProfileState, shallowEqual);
  const onLinkClicked = (e, path, key) => {
    e.preventDefault();
    LocalEventsService.emit('checkEvents', { hello: 'hi' });
    switch (key) {
      case 'DOCTOR_APPOINTMENTS':
        navigate('/doctor/appointments/upcoming');
        return;
      case 'DOCTOR_WALLET':
        navigate('/doctor/wallet/income');
        return;
      default:
        navigate(path);
    }
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleCloseMenu = () => setIsMenuOpen(false);
  const handleStateChange = (state) => setIsMenuOpen(state.isOpen);

  let notificationNumber;
  let notificationClass;
  if (unseen <= 9) {
    notificationNumber = <div className="number">{unseen}</div>;
    notificationClass = 'count';
  } else if (unseen > 99) {
    notificationNumber = <div className="number">99+</div>;
    notificationClass = 'count plus';
  } else {
    notificationNumber = <div className="number">{unseen}</div>;
    notificationClass = 'count big';
  }
  const solveRoute = (routeKey, routePath) => {
    switch (routeKey) {
      case 'DOCTOR_APPOINTMENTS':
        return '/doctor/appointments/upcoming';
      case 'DOCTOR_WALLET':
        return '/doctor/wallet/income';
      default:
        return routePath;
    }
  };

  if (isTwilioScreen()) {
    return null;
  }

  if (isMobile) {
    return (
      <>
        {profileData.isPharmacist && (
          <Menu
            isOpen={isMenuOpen}
            onStateChange={handleStateChange}
            right
            burgerButtonClassName="mobile-menu"
          >
            <ul>
              <li key={_.uniqueId()}>
                <NavLink
                  onClick={(e) => {
                    onLinkClicked(e, 'recommend', 'DOCTOR_RECOMMEND');
                    handleCloseMenu();
                  }}
                  key="recommend"
                  to={solveRoute('DOCTOR_RECOMMEND', 'recommend')}
                  className="cursor-pointer recommend"
                >
                  <div>
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.menuColor}
                      size={Fonts.s20}
                      icon="Home"
                    />
                    <span>{`${i18next.t('mainScreen')}`}</span>
                  </div>
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.darkGrey}
                    size="20"
                    icon="Right_pointer"
                  />
                </NavLink>
              </li>
              <li key={_.uniqueId()}>
                <NavLink
                  onClick={(e) => {
                    onLinkClicked(e, 'account', 'DOCTOR_ACCOUNT');
                    handleCloseMenu();
                  }}
                  key="recommend"
                  to={solveRoute('DOCTOR_ACCOUNT', 'account')}
                  className="cursor-pointer recommend"
                >
                  <div>
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.menuColor}
                      size={Fonts.s20}
                      icon="Home"
                    />
                    <span>{`${i18next.t('mainScreen')}`}</span>
                  </div>
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.darkGrey}
                    size="20"
                    icon="Right_pointer"
                  />
                </NavLink>
              </li>
            </ul>
          </Menu>
        )}

        {!profileData.isPharmacist && (
          <Menu
            isOpen={isMenuOpen}
            onStateChange={handleStateChange}
            right
            burgerButtonClassName="mobile-menu"
          >
            <ul>
              {routes.map(
                (
                  route: JSX.IntrinsicAttributes & {
                    component?: any;
                    path?: any;
                    exact?: any;
                    routes?: any;
                    className?: any;
                    name?: any;
                    classIcon?: any;
                    iconName?: any;
                  },
                ) => {
                  return (
                    <li key={route.key}>
                      <NavLink
                        onClick={(e) => {
                          onLinkClicked(e, route.path, route.key);
                          handleCloseMenu();
                        }}
                        key={route.path}
                        to={solveRoute(route.key, route.path)}
                        className={`cursor-pointer ${route.className}`}
                      >
                        <div>
                          <CustomIcon
                            className="custom-icon"
                            color={Colors.menuColor}
                            size={Fonts.s20}
                            icon={route.iconName}
                          />
                          <span>{`${i18next.t(route.name)}`}</span>
                          {route.key === 'DOCTOR_NOTIFICATIONS' && unseen > 0 && (
                            <div className={notificationClass}>{notificationNumber}</div>
                          )}
                        </div>
                        <CustomIcon
                          className="custom-icon"
                          color={Colors.darkGrey}
                          size="20"
                          icon="Right_pointer"
                        />
                      </NavLink>
                    </li>
                  );
                },
              )}
            </ul>
          </Menu>
        )}
      </>
    );
  }
  return (
    <nav>
      {profileData.isPharmacist && (
        <Fragment key={_.uniqueId()}>
          <NavLink
            onClick={(e) => {
              onLinkClicked(e, 'recommend', 'DOCTOR_RECOMMEND');
              handleCloseMenu();
            }}
            to={solveRoute('DOCTOR_RECOMMEND', 'recommend')}
            className="recommend"
          >
            <CustomIcon
              className="custom-icon"
              color={Colors.menuColor}
              size={Fonts.s32}
              icon="Home"
            />
            <span>{`${i18next.t('mainScreen')}`}</span>
          </NavLink>
          <NavLink
            onClick={(e) => {
              onLinkClicked(e, 'account', 'DOCTOR_ACCOUNT');
              handleCloseMenu();
            }}
            to={solveRoute('DOCTOR_ACCOUNT', 'account')}
            className="recommend"
          >
            <CustomIcon
              className="custom-icon"
              color={Colors.menuColor}
              size={Fonts.s32}
              icon="Profil"
            />
            <span>{`${i18next.t('myAccount')}`}</span>
          </NavLink>
        </Fragment>
      )}
      {!profileData.isPharmacist &&
        routes.map(
          (
            route: JSX.IntrinsicAttributes & {
              component?: any;
              path?: any;
              exact?: any;
              routes?: any;
              className?: any;
              name?: any;
              classIcon?: any;
              iconName?: any;
            },
          ) => {
            return (
              <Fragment key={route.path}>
                {route.path === 'notifications' && <LanguageNavSelector />}
                <NavLink
                  onClick={(e) => {
                    onLinkClicked(e, route.path, route.key);
                    handleCloseMenu();
                  }}
                  to={solveRoute(route.key, route.path)}
                  className={route.className}
                >
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.menuColor}
                    size={Fonts.s32}
                    icon={route.iconName}
                  />
                  <span>{`${i18next.t(route.name)}`}</span>
                  {route.key === 'DOCTOR_NOTIFICATIONS' && unseen > 0 && (
                    <div className={notificationClass}>{notificationNumber}</div>
                  )}
                </NavLink>
              </Fragment>
            );
          },
        )}
    </nav>
  );
};

DoctorTopMenu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  unseen: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default DoctorTopMenu;
