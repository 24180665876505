import 'doctor/styles/video-call.css';
import React, { memo, useEffect } from 'react';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { useDispatch } from 'react-redux';
import Alerts from 'shared/components/Alerts';
import { appointmentEndConsultationRequest } from 'shared/redux/actions/AppointmentActions';
import { doctorAppointmentUpdatesRequest } from 'shared/redux/actions/DoctorAppointmentsActions';
import { useTranslation } from 'react-i18next';
import { getEnv } from 'shared/services/EnvService';
import StorageService from 'shared/services/StorageService';
import dayjs from 'dayjs';
import {
  LocalAudioTrack,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteUser,
  RemoteVideoTrack,
  useJoin,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  useRemoteAudioTracks,
  useRemoteUsers,
  useRemoteVideoTracks,
  useRTCClient,
} from 'agora-rtc-react';
import NavigationService from '../../../shared/services/NavigationService';
import { useAgoraContext } from '../../../shared/agora/AgoraManager';
import config from '../../../shared/agora/config';
import { appointmentLocalStepsRequest } from '../../../shared/redux/actions/PatientAppointmentActions';

const DoctorVideoCallTabVideo = (props) => {
  const { t } = useTranslation();
  const {
    room,
    endTwilioCall,
    appointmentType,
    appointmentId,
    enableAudio,
    enableVideo,
    patient,
    doctor,
    showTimeBubble,
    isInitialTime,
    isOtherUserConnected = false,
    otherVideoEnabled,
    otherAudioEnabled,
    handleMic,
    localCameraTrack,
    toggleVideo,
  } = props;
  const dispatch = useDispatch();

  const doEndPress = (e) => {
    Alerts.okCancelAlert(
      `${t('warning')}`,
      `${t('validations.endCall')}`,
      () => {
        dispatch(appointmentEndConsultationRequest({ appointmentId }));
        dispatch(
          doctorAppointmentUpdatesRequest({ status: 'needConclusion', callStatus: 'terminated' }),
        );
        NavigationService.navigate('/doctor/home');
      },
      () => {},
    );
  };

  const LocalVideoView = () => {
    if (!doctor?.pictureMedia) {
      return (
        <div className="local-twilio-user-image">
          <div className="twilio-user-text">{`${doctor?.firstName?.charAt(
            0,
          )}${doctor?.lastName?.charAt(0)}`}</div>
        </div>
      );
    }
    return (
      <div className="avatar-picture" style={{ backgroundImage: `url(${doctor?.pictureMedia})` }} />
    );
  };
  const PatientVideoView = () => {
    if (!patient?.picture) {
      return (
        <div className="agora-user-text">{`${patient?.firstName?.charAt(0) ?? ''}${
          patient?.lastName?.charAt(0) ?? ''
        }`}</div>
      );
    }
    return (
      <div className="avatar-picture" style={{ backgroundImage: `url(${patient?.picture})` }} />
    );
  };

  const renderLastMinutes = () => {
    if (showTimeBubble && isInitialTime) {
      return (
        <div className="info-message minutes-left green">
          <CustomIcon
            className="custom-icon"
            color={Colors.mediumGrey}
            size="24"
            icon="Warning_2"
          />
          <div className="messages">
            <div className="text">
              {`${t('left2minutes')} ${
                ['free_talk', 'freeTalk'].indexOf(appointmentType) === -1
                  ? t('consultation')
                  : t('call')
              }`}
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderInfoBubble = () => {
    if (!enableVideo || !enableAudio) {
      return (
        <div className="info-message">
          <CustomIcon
            className="custom-icon"
            color={Colors.mediumGrey}
            size="24"
            icon="Warning_2"
          />
          <div className="messages">
            {!enableAudio && <div className="text">{t('micOffForPatient')}</div>}
            {!enableVideo && <div className="text">{t('videoOffForPatient')}</div>}
          </div>
        </div>
      );
    }
    return null;
  };

  const renderOtherInfoBubble = () => {
    const NoCamera = memo(() => {
      return !otherVideoEnabled ? <div className="text">{t('patientVideoOff')}</div> : null;
    });
    const NoMic = memo(() => {
      return !otherAudioEnabled ? <div className="text">{t('patientMicOff')}</div> : null;
    });
    if (!isOtherUserConnected || !otherVideoEnabled || !otherAudioEnabled) {
      return (
        <>
          <div className="info-message">
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="24"
              icon="Warning_2"
            />
            <div className="messages">
              {!isOtherUserConnected ? (
                <div className="text">{t('patientIsNotConnected')}</div>
              ) : (
                <>
                  <NoCamera />
                  <NoMic />
                </>
              )}
            </div>
          </div>
        </>
      );
    }
    return null;
  };

  //* ******* AGORA ********************************************************************
  const remoteUsers = useRemoteUsers();
  const remoteUser = remoteUsers[0];

  //* END ******* AGORA ********************************************************************

  return (
    <div className="video-tab">
      <div className="video-container">
        <div className="remote-view-wrapper">
          <div className="mwp">
            {/* Render remote users' video and audio tracks */}
            <RemoteUser user={remoteUser} className="remoteUserComponent" />
            <RemoteAudioTrack track={remoteUser?.audioTrack} play />
            {remoteUser?.hasVideo ? (
              <div className="vid">
                <RemoteUser user={remoteUser} playVideo />
                {/* <RemoteVideoTrack track={remoteUser?.videoTrack} play /> */}
              </div>
            ) : (
              <PatientVideoView />
            )}
          </div>
        </div>
        <div className="local-view-wrapper">
          {/* Render the local video track */}
          <div className="vid">
            {enableVideo ? <LocalVideoTrack track={localCameraTrack} play /> : <LocalVideoView />}
          </div>
        </div>
        <div className="video-alerts">
          {renderLastMinutes()}
          {renderInfoBubble()}
          {renderOtherInfoBubble()}
        </div>
      </div>
      <div className="video-actions">
        <div className="close" onClick={doEndPress}>
          <div className="circle">
            <CustomIcon className="custom-icon" color={Colors.white} size="28" icon="Call_end" />
          </div>
          <div className="title">{t('close')}</div>
        </div>
        <div
          className={enableAudio ? 'microphonemicrophone inactive' : 'microphone inactive'}
          onClick={handleMic}
        >
          <div className="circle">
            <CustomIcon
              className="custom-icon"
              color={!enableAudio ? Colors.white : Colors.darkGrey}
              size="28"
              icon={enableAudio ? 'Microphone' : 'Microphone_off'}
            />
          </div>
          <div className="title">{t('microphone')}</div>
        </div>
        <div className={enableVideo ? 'camera' : 'camera inactive'} onClick={toggleVideo}>
          <div className="circle">
            <CustomIcon
              className="custom-icon"
              color={enableVideo ? Colors.darkGrey : Colors.white}
              size="28"
              icon={enableVideo ? 'Video' : 'Video_off'}
            />
          </div>
          <div className="title">{t('camera')}</div>
        </div>
      </div>
    </div>
  );
};

export default DoctorVideoCallTabVideo;
